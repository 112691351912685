@import '../../styles/import';

.partner-detail-page {
    margin-top: spacing('xl');

    @include desktop() {
        margin-top: spacing('6xl');
    }

    &__banner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &-image {
            max-width: 100%;
            object-fit: contain;
        }
    }
}
