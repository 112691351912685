@import '../../../styles/import';

.item {
  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 18px;
    line-height: 21px;
    padding-right: 10px;
    padding-left: 10px;

    &.is-active {
      background-color: var(--white);

      /* stylelint-disable-next-line selector-class-pattern */
      .item__link {
        color: var(--black);
      }
    }

    @media screen and (max-width: 1200px) {
      font-size: 14px;
      line-height: 16.5px;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &__link {
    // color: var(--white);

    @each $theme in $themes {
      &.item__link--#{$theme} {
        color: var(--event-#{$theme}-second-nav-text-color);
      }
    }

    @each $theme in $themes {
      &.item__link--#{$theme}:hover {
        color: var(--event-#{$theme}-second-nav-text-color);
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    // &:hover {
    //   color: var(--white);
    // }
  }
}