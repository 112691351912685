.cf-deal-term {
    .deal-logo {
        margin-bottom: 8px;

        >img {
            max-height: 25px;
            max-width: 60px;
        }
    }

    .ttl {
        font-family: Barlow, Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size: 22px;
        line-height: 130%;
        color: var(--cf-primary);
    }

    .sub-ttl {
        color: var(--cf-gray2, #484B55);
        font-size: 12px;
        line-height: 160%;
        letter-spacing: -0.11px;
    }

    .ct {
        padding-top: 24px;
        font-size: 11px;
        line-height: 160%;
        letter-spacing: -0.11px;
        color: var(--cf-gray2, #484B55);

        .ct-ttl {
            margin-bottom: 5px;
        }

        strong {
            font-family: Barlow, Arial, Helvetica, sans-serif;
            font-weight: normal;
            color: var(--cf-primary);
        }

        p {
            font-size: 11px;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}