/** FOOTER CSS */
@import '../../../styles/import';

.footer-copyright {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;

    .text-col {
        max-width: 779px;
        gap: 30px;

        .txt {
            opacity: 0.7;
        }
    }

    .copyright {
        >span {
            margin-right: 47px;
        }

        >a {
            text-decoration: underline;
            color: white;

            &:hover {
                text-decoration: none;
            }

            &.privacy {
                margin-right: 23px;
            }
        }
    }

    .w-logo {
        width: 81px;
        flex: 0 0 81px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .term {
        display: none;
    }

    @include media-breakpoint-down('xs') {
        font-size: 14px;

        .cf-flex {
            &.flex-row {
                gap: 28px;
                flex-direction: column;
                align-items: start;
            }
        }

        .text-col {
            gap: 28px;
        }

        .term {
            display: flex;

            a {
                text-decoration: underline;
                color: white;

                &.privacy {
                    margin-right: 30px;
                }
            }
        }

        .copyright {
            a {
                display: none;
            }
        }

        .w-logo {
            flex: auto;
        }
    }
}
