.cf-menu-btn-sm {
    .call-menu {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
    }
}

.hamburger {
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    &#hamburger-1 {
        .line {
            display: block;
            background-color: white;
            width: 24px;
            height: 2px;
            margin: 10px auto;
            transition: all 0.2s ease-in-out;
        }

        &.is-active {
            .line{
                &:nth-child(1) {
                    transform: translateY(6px) rotate(45deg);
                }
                
                &:nth-child(2) {
                    transform: translateY(-6px) rotate(-45deg);
                }
            }
        }
    }
}
