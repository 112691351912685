@import '../../../../../styles/import';

.cf-become-member-blk {
    padding: 160px 0;
    color: var(--cf-purple);

    @include media-breakpoint-down('xlg') {
        padding: 96px 0;
    }

    @include media-breakpoint-down('xs') {
        padding: 80px 0;
    }

    >.cf-flex {
        gap: 40px;

        @include media-breakpoint-down('xlg') {
            flex-direction: column;
            align-items: start;
        }
    }

    .content {
        width: 376px;

        .ttl {
            color: var(--cf-purple);
            margin: 0;
            margin-bottom: 16px;
        }

        .desc {
            font-size: 16px;
            margin-bottom: 36px;
        }

        @include media-breakpoint-down('xlg') {
            width: 100%;
        }

        @include media-breakpoint-down('md') {
            text-align: center;
            width: 376px;
            margin: 0 auto;

            .cta-blk {
                > .button {
                    margin: 0 auto;
                }
            }
        }

        @include media-breakpoint-down('xs') {
            width: auto;
            max-width: 350px;
        }
    }
}

.three-list {
    @include media-breakpoint-down('xlg') {
        width: 100%;
    }

    >.cf-flex {
        gap: 34px;

        @include media-breakpoint-down('xlg') {
            justify-content: space-between;
        }

        @include media-breakpoint-down('xs') {
            flex-direction: column;
            align-items: center;
            gap: 42px;
        }
    }

    .benefit-item {
        width: 230px;

        > svg {
            margin-bottom: 23px;
        }

        .title {
            font-size: 20px;     
            line-height: 120%;
            margin-bottom: 11px;
        }

        .desc {
            font-size: 13px;
        }

        @include media-breakpoint-down('xs') {
            width: 324px;
            text-align: center;

            > svg {
                margin-bottom: 16px;
            }

            .title {
                margin-bottom: 9px;
            }

            .desc {
                font-size: 14px;
            }
        }
    }
}