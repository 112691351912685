@import '../../styles/import';

.brand-deals {
    margin-top: spacing('6xl');

    @include desktop() {
        margin-top: spacing('xl');
    }

    &__title {
        @include font('barlow', 'xl', 'lg', 'heavy');

        margin-top: 0;
    }

    &__title-grid {
        @include desktop() {
            margin-bottom: spacing('default');
        }
    }
}