@import '../../styles/import';

.styleguide-spacing {
    &__element {
        display: inline-block;
        margin-right: spacing('xl');
    }

    &__spacer {
        height: spacing('lg');
        background-color: var(--secondary-alternate);

        &--unit {
            &-none {
                width: spacing('none');
            }

            &-2xs {
                width: spacing('2xs');
            }

            &-xs {
                width: spacing('xs');
            }

            &-default {
                width: spacing('default');
            }

            &-md {
                width: spacing('md');
            }

            &-lg {
                width: spacing('lg');
            }

            &-xl {
                width: spacing('xl');
            }

            &-2xl {
                width: spacing('2xl');
            }

            &-3xl {
                width: spacing('3xl');
            }

            &-4xl {
                width: spacing('4xl');
            }

            &-5xl {
                width: spacing('5xl');
            }

            &-6xl {
                width: spacing('6xl');
            }

            &-7xl {
                width: spacing('7xl');
            }
        }
    }
}
