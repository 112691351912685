@import '../../styles/import';

.icon {
    &--circle-background {
        width: 120px;
        height: 120px;
        background: var(--secondary-alternate-transparent);
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
