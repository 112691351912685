@import '../../styles/import';

.input {
    $self: &;

    position: relative;
    margin-top: spacing('2xl');

    &__field {
        @include font('barlow', 'md', 'sm');

        width: 100%;
        border: 1px solid var(--light-grey);
        padding: spacing('md');
        border-radius: 2px;
        color: var(--body);
        box-sizing: border-box;

        &::placeholder {
            color: var(--clear-grey);

            #{$self}--error & {
                color: var(--error);
            }
        }

        &:focus {
            border-color: var(--secondary-alternate);
        }

        #{$self}--error & {
            border-color: var(--error);
            color: var(--error);
        }

        #{$self}--has-tooltip & {
            padding-right: spacing('6xl');
        }
    }

    &__error-message {
        @include font('barlow', 'xs', 'xs');

        margin-top: spacing('default');
        color: var(--error);
    }

    &__tooltip-trigger {
        position: absolute;
        top: 50%;
        right: spacing('md');
        transform: translate(0, -50%);
        line-height: 0;
        cursor: pointer;
    }

    &__tooltip {
        display: none;
        position: absolute;
        top: 110%;
        width: 90%;
        right: 0;
        background: var(--white);
        border-radius: 4px;
        box-shadow: 0 0 5px var(--clear-grey);
        z-index: z-index('above-page-content');
        padding: spacing('md');
        box-sizing: border-box;
        flex-direction: row;
        justify-content: space-between;

        #{$self}--tooltip-shown & {
            display: flex;
        }

        // show an arrow up above tooltip
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: -8px;
            right: 14px;
            width: 16px;
            height: 16px;
            background: var(--white);
            transform: rotate(45deg);
            box-shadow: -1px -1px 3px var(--clear-grey);
        }

        // hide the box shadow under the arrow
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: spacing('md');
            background: var(--white);
            border-radius: 4px;
        }
    }

    &__tooltip-close {
        line-height: 0;
        cursor: pointer;
        order: 1;
        margin: 0 5px 0 spacing('md');
        z-index: z-index('above-page-content');

        span,
        svg {
            width: 12px;
            height: 12px;
            display: inline-block;
        }
    }

    &__tooltip-content {
        @include font('barlow', 'xs', 'xs');

        margin: 0;
    }

    &__eye-trigger {
        position: absolute;
        top: 50%;
        right: spacing('md');
        transform: translate(0, -50%);
        line-height: 0;
        cursor: pointer;
    }
}