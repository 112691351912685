@import '../../styles/import';

.cfa-block {
    display: flex;
    flex-direction: column;
    position: relative;

    $props: ('top', 'right', 'bottom', 'left');

    @each $key, $value in $spacing-map {
        @each $prop in $props {
            &--#{$key}-#{$prop} {
                padding-#{$prop}: #{$value};
            }
        }
    }
}
