@import '../../styles/import';

.account-form {
  $self: &;

  &__page-heading {
    @include font('barlow', 'xxl', 'xl', 'medium');

    margin-top: spacing('6xl');
    text-align: center;

    @include desktop() {
      margin-top: spacing('7xl');
    }
  }

  &__page-intro {
    @include font('barlow', 'md', 'lg');

    margin-top: spacing('2xl');
    text-align: center;
  }

  &__first-form-field {
    margin-top: spacing('5xl');
  }

  &__first-checkbox {
    margin-top: spacing('xl');

    @include desktop() {
      margin-top: spacing('lg');
    }
  }

  &__submit {
    margin-top: spacing('4xl');
    width: 100%;

    @include desktop() {
      margin-top: spacing('6xl');
    }

    #{$self}--has-error & {
      margin-top: spacing('2xl');

      @include desktop() {
        margin-top: spacing('3xl');
      }
    }
  }

  a {
    @include underline-on-hover();

    & {
      color: var(--primary);
      cursor: pointer;
    }
  }

  &__footer {
    @include font('barlow', 'md', 'lg');

    margin-top: spacing('2xl');
    text-align: center;
  }

  &__error-message {
    @include font('barlow', 'md', 'lg');

    color: var(--error);
    margin-top: spacing('4xl');

    @include desktop() {
      text-align: center;
      margin-top: spacing('6xl');
    }
  }
}
