@import '../../../styles/import';

.recent-searches {
    &__message {
        color: var(--body-light);
    }

    &__action {
        & + & {
            margin-top: spacing('lg');
        }
    }

    .search-result + .search-result {
        margin-top: spacing('2xl');
    }

    .search-result:last-of-type {
        margin-bottom: spacing('4xl');
    }
}
