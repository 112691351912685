/* FONT FACE */
@font-face {
    font-family: 'SharpGroteskBook20';
    src: url('../../fonts/SharpGroteskBook20.woff2') format('woff2'),
        url('../../fonts/SharpGroteskBook20.eot') format('woff'),
        url('../../fonts/SharpGroteskBook20.ttf') format('truetype');
}

@font-face {
    font-family: 'SharpGroteskSmBold20';
    src: url('../../fonts/SharpGroteskSmBold20.eot') format('woff2'),
        url('../../fonts/SharpGroteskSmBold20.eot') format('woff'),
        url('../../fonts/SharpGroteskSmBold20.ttf') format('truetype');
}