@import '../../styles/import';

.cta-banner {
  $self: &;

  background: var(--white);
  padding: spacing('6xl') 0;

  @include desktop() {
    padding: spacing('7xl') 0;
  }

  &__title {
    @include font('barlow', 'xxl', 'xl', 'medium');

    margin-top: 0;
    text-align: center;

    @each $theme in $themes {
      &--#{$theme} {
        color: var(--event-#{$theme}-cta-banner-title-text-color);
      }
    }
  }

  &__description-wrapper {
    @include media-breakpoint-down(md) {
      padding: 0 spacing('md');
    }
  }

  &__description {
    @include font('barlow', 'md', 'sm');

    margin-top: spacing('md');
    text-align: center;

    @each $theme in $themes {
      &--#{$theme} {
        color: var(--event-#{$theme}-cta-banner-description-text-color);
      }
    }

    @include desktop() {
      margin-top: spacing('lg');
    }

    #{$self}--no-cta & {
      padding: 0 spacing('default');

      @include media-breakpoint-up('lg') {
        padding: 0 170px;
      }
    }
  }

  &__video-wrapper {
    margin-top: spacing('xl');
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  &__cta-wrapper {
    margin-top: spacing('2xl');

    @include desktop() {
      margin-top: spacing('4xl');
    }

    #{$self}--no-cta & {
      margin-top: 0;
    }

    #{$self}--become-member & {
      text-transform: uppercase;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__button {
    @each $theme in $themes {
      &--#{$theme} {
        color: var(--event-#{$theme}-cta-banner-button-text-color) !important;
        background-color: var(--event-#{$theme}-cta-banner-button-background-color) !important;

        path {
          fill: var(--event-#{$theme}-secondary-text-colour);
        }

        &:hover {
          color: var(--event-#{$theme}-secondary-text-colour);
        }
      }
    }
  }

  &--become-member {
    @include desktop() {
      margin-bottom: spacing('5xl');
    }
  }

  // Fashion Frenzy Banner CSS

  /* &--extra-banner {
        text-align: center;
        background: #000000;

        img {
            max-width: 300px;
            padding: 30px 0;
        }
    } */

  @each $theme in $themes {
    &--#{$theme}-standard {
      background: var(--event-#{$theme}-background-colour);
    }

    &--#{$theme} {
      background: var(--event-#{$theme}-header-colour);
    }

    &--#{$theme}-custom {
      @if $theme == 'travel' {
        background-image: var(--event-#{$theme}-cta-banner-custom-background-image);
        background-repeat: no-repeat;
        background-size: cover;
      } @else {
        background: var(--event-#{$theme}-custom-background-colour);
      }
    }
  }
}
