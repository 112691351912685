@import '../../../styles/import';

.search-footer {
    margin: spacing('6xl') spacing('md') 0;
    flex-direction: column;
    padding-left: spacing('default');
    padding-right: spacing('default');
    box-sizing: border-box;

    @include media-breakpoint-up('xs') {
        max-width: 348px;
        margin: spacing('6xl') auto 0;
        padding-left: 0;
        padding-right: 0;
    }

    &__load-more {
        margin-top: spacing('xl');
        display: none;
        width: 100%;
        text-transform: uppercase;

        &--visible {
            display: block;
        }
    }
}
