@import '../../styles/import';

.popup-card {
    text-align: center;

    h1 {
        @include font(barlow, xxl, xl, heavy);

        margin: spacing(default) 0 spacing(xs);
    }

    h2 {
        margin: spacing(lg) 0 spacing(xs);
    }

    p {
        @include font(catamaran, lg, md, regular);

        margin: spacing('2xs') 0;
    }

    &__product-name {

        /* stylelint-disable-line */
        h1,
        p {
            color: var(--white);
        }
    }

    &__question {
        color: var(--white);
        margin: spacing('xl') 0;

        p {
            margin-top: spacing(lg);
        }
    }

    &__countdown {
        margin: spacing(md) 0;

        table {
            margin: 0 auto;
        }

        &__numbers {
            td {
                @include font(barlow, xxxl, 'xxl', medium);

                padding: spacing(md) spacing(xs);

                &:first-child,
                &:last-child {
                    border-radius: 5px;
                }
            }
        }
    }

    &__confirm-message {
        h1 {
            @each $theme in $themes {
                .popup-card--#{$theme} & {
                    color: var(--event-#{$theme}-text-colour);
                }
            }
        }
    }

    &__input {
        .input {
            margin-top: 0;
            margin-bottom: spacing('default');
        }
    }

    &__button {
        .ant-form-item:first-child {
            margin-bottom: 0;
        }

        &__answer {
            border: none;
            padding: spacing(md) 0;

            @each $theme in $themes {
                .popup-card--#{$theme} & {
                    background-color: var(--event-#{$theme}-secondary-background-colour);
                }
            }
        }
    }

    &__tcs {
        margin-top: spacing(md);

        p {
            @include font(catamaran, xs, '2xs', regular);

            color: var(--white);
            line-height: 1;
            margin: 0;
        }

        a {
            color: var(--white);

            &:hover {
                color: var(--white);
                text-decoration: underline;
            }
        }
    }

    &__image-wrapper {
        background-color: var(--white);
        height: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }

    &__image {
        width: 100%;
        height: auto;
    }

    @include media-breakpoint-down('md') {
        h1 {
            @include font(barlow, lg, lg, heavy);

            margin: spacing('2xs') 0;
        }

        h2 {
            margin: spacing(xs) 0;
        }

        p {
            @include font(catamaran, sm, xs, regular);

            margin: spacing('2xs') 0;
        }

        &__question {
            margin: spacing('xs') 0;

            p {
                margin-top: spacing(xs);
            }
        }

        &__countdown {
            margin: spacing(md) 0 spacing('2xs');

            &__numbers {
                td {
                    @include font(barlow, xl, lg, medium);

                    padding: spacing(xs);
                }
            }
        }

        &__tcs {
            p {
                @include font(catamaran, 'xxxs', '2xs', regular);
            }
        }

        &__image-col {
            margin-bottom: spacing(md);
        }

        &__image {
            width: 70%;
            height: auto;
            margin: 0 auto;
        }
    }
}