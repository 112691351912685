@import '../../styles/import';

.item {
  @include media-breakpoint-up('md') {
    flex-basis: 33%;
  }

  @include media-breakpoint-up('lg') {
    flex-basis: 20%;
  }
}

.link {
  @include font('barlow', 'md', 'sm', 'heavy');

  display: flex;
  color: var(--primary);
  padding: spacing('xs') spacing('xl');
  text-decoration: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  @include desktop() {
    @include font('barlow', 'xs', 'lg', 'heavy');

    padding: 0;
    margin: 0 spacing('2xs');
    border-style: none;
    margin-top: spacing('xs');
  }
}

.button {
  @include font('barlow', 'sm', 'lg', 'medium');

  width: 100%;
  height: 40px;
  transition: opacity 160ms;
  color: var(--white);

  &:hover {
    opacity: 0.8;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.noEvent {
    background-color: var(--secondary-alternate);
  }

  @each $theme in $themes {
    &.#{$theme} {
      background-color: var(--event-#{$theme}-header-colour);
    }
  }
}