@import '../../styles/import';

.modal {
  $self: &;

  &__overlay {
    @include z-index('overlay');

    -webkit-overflow-scrolling: touch;
    position: fixed;
    inset: 0;
    background-color: var(--overlay);
    opacity: 0;
    transition: opacity 400ms;
    overflow: hidden;

    #{$self}--size-responsive & {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  &__content {
    -webkit-overflow-scrolling: touch;
    background: #fff;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    inset: 0;
    overflow-y: auto;

    #{$self}--size-default &,
    #{$self}--size-responsive & {
      @include desktop() {
        max-width: 800px;
        position: relative;
        transform-origin: center;
        transition: margin 400ms;
        border-radius: 4px;
        box-shadow: $standardBoxShadow;
        margin: 10vh auto;
        max-height: 600px;
        overflow: hidden;

        @media screen and (max-height: 750px) {
          max-height: 80vh;
        }

        @media screen and (min-height: 800px) {
          margin: 15vh auto;
        }

        @include ie() {
          height: 600px;
        }
      }
    }

    #{$self}--size-responsive & {
      @include media-breakpoint-up('md') {
        overflow: auto;
        max-height: 700px;
        max-width: 700px;
        margin-top: 0;
        margin-bottom: 0;
      }

      @include media-breakpoint-up('lg') {
        max-width: 850px;
      }

      @include media-breakpoint-up('xl') {
        max-width: 1000px;
      }
    }

    #{$self}--size-fullscreen & {
      padding-top: spacing('3xl');
      padding-bottom: spacing('3xl');

      @include desktop() {
        padding-top: spacing('7xl');
        padding-bottom: spacing('7xl');
      }
    }

    &--padding-default {
      @include desktop() {
        padding: spacing('2xl');
      }
    }

    &--padding-large {
      @include desktop() {
        padding: spacing('6xl');
      }
    }
  }

  &__close-button {
    background: none;
    margin: 0;
    border: 0;
    line-height: 1;
    padding: spacing('default');
    cursor: pointer;
    outline: 0;

    svg {
      display: block;
      height: spacing('2xl');
      width: spacing('2xl');
    }

    &--inline {
      position: absolute;
      top: 20px;
      right: 20px;

      @include desktop() {
        top: 35px;
        right: 35px;
      }

      #{$self}__content--size-responsive & {
        top: 10px;
        right: 10px;
      }
    }
  }

  &__close-button-cell {
    text-align: right;
  }

  &__column-header {
    @include font('barlow', 'md', 'md', 'medium');

    margin-bottom: spacing('2xl');

    @include mobile() {
      margin-top: spacing('6xl');
    }
  }
}