@import '../../../../styles/import';

.cf-menu-sm {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    opacity: 0;
    overflow-y: auto;
    z-index: -100;
    pointer-events: none;
    background: var(--cf-primary);
    transition: opacity 0.25s linear;
    
    a {
        font-size: 22px;
        color: white;
    }

    .cf-sign-sm {
        padding-top: 52px;

        .cf-flex {
            gap: 24px;
        }

        .button {
            font-size: 18px;
            width: 100%;
            max-width: 330px;
            height: 50px;
        }
    }

    .cf-menu-sm-content {
        padding-top: 85px;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid rgb(248 244 253 / 20%);

        .nav-sm {
            padding-top: 45px;
            padding-bottom: 52px;
            
            ul {
                padding: 0;
                gap: 32px;
            }
        } 
    }

    

    @include media-breakpoint-down('md') {
        display: block;
    }
}