@import '../../styles/import';

.brand-navigation {
  background: var(--white);
  border-bottom: solid 1px var(--light-grey);
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  .touchevents & {
    scrollbar-width: none;

    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }

  @include desktop() {
    overflow-x: visible;
  }

  &__grid {
    margin: 0 auto;

    @include media-breakpoint-down('xxs') {
      width: 100%;
    }

    & {
      width: 1320px !important;
    }
  }

  &__row {
    @include media-breakpoint-down(xs) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__cell {
    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }

    & {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  &__spacer {
    display: block;
    padding-right: spacing('xl');

    @include desktop() {
      display: none;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    @include font(barlow, md, md);

    $item-height: spacing('6xl') + spacing('xs');

    line-height: $item-height;
    height: $item-height;
    color: var(--text-light-gray);
    cursor: pointer;
    margin-right: spacing('4xl');
    list-style: none;

    &:nth-last-of-type(2) {
      margin-right: 0;
    }

    @include desktop() {
      margin-right: 0;
    }

    &--is-missing {
      cursor: default;
      opacity: 0.3;
    }

    &--active {
      color: var(--primary);
      border-bottom: solid spacing('2xs');
      box-sizing: border-box;
    }
  }
}
