/**
 ** DEAL BROWSE CSS
 **/
@import '../../../../../styles/import';

.cf-deal-item {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    background-color: white;
    width: 262px;
    box-shadow: 0 0 20px 0 rgb(224 216 234 / 40%);

    &.expired {
        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            inset: 0;
            background-color: rgb(133 133 133 / 60%);
        }
    }

    .thumb {
        position: relative;
        margin: 0;

        >a {
            display: block;
            line-height: 0;
        }

        .deal-badge {
            position: absolute;
        }

        .thumb-img {
            width: 100%;
            height: auto;
        }

        .deal-logo {
            position: absolute;
            overflow: hidden;
            left: 13px;
            bottom: -20px;
            height: 40px;
            max-width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 7px;
            border-radius: 5px;
            border: 1px solid var(--cf-grey5, #EFF1F9);
            background: white;

            >img {
                max-height: 30px;
                max-width: 45px;
                object-fit: cover;
            }
        }
    }

    .content {
        color: var(--cf-grey2, #484B55);
        font-size: 10px;
        line-height: 160%;
        padding: 30px 16px 14px;

        .ttl {
            font-size: 14px;
            margin-bottom: 8px;
            height: 40px;
            overflow: hidden;

            >a {
                color: var(--cf-primary);
                line-height: 140%;
            }
        }

        .desc, .link-blk {
            opacity: 0.7;
        }

        .desc {
            font-size: 11px;
            margin-bottom: 19px;
            height: 36px;
            overflow: hidden;
        }

        .link-blk {
            > span {
                text-decoration: underline;
                color: var(--cf-grey2, #484B55);
                opacity: 0.6;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }      
        }
    }

    @include media-breakpoint-down('xs') {
        width: calc(100vw / 2 - 28px);
    }

    @include media-breakpoint-down('xxs') {
        width: 100%;
        max-width: 350px;
        box-shadow: 0 0 12px 0 #E0D8EA;

        .content {
            color: var(--cf-grey2, #484B55);
            font-size: 12px;
            padding: 26px 16px 14px;
    
            .ttl {
                font-size: 16px;
                margin-bottom: 0;
                height: auto;
            }

            .desc {
                margin-bottom: 8px;
                height: auto;
            }

            .link-blk {
                text-align: right;
            }
        }
    }
}

.cf-deal-badge {
    position: absolute;
    z-index: 2;
    top: 7px;
    right: 7px;

    @include media-breakpoint-down('xxs') {
        top: 12px;
        right: 16px;
    }
}