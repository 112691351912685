@import '../../styles/import';

body {
    padding-top: var(--header-height);
}

#root,
body,
html {
    height: 100%;
    box-sizing: border-box;
}

.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-family: font-family('barlow');

    &__header {
        @include z-index('layout-header');

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
    }

    &.cf-layout {
        font-family: font-family('barlow');
        font-style: normal;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
    }
}