@import '../../styles/import';

.confirm-sign-up {
  &__page-heading {
    @include font('barlow', 'xxl', 'xl', 'medium');

    margin-top: spacing('6xl');
    text-align: center;

    @include desktop() {
      margin-top: spacing('7xl');
    }
  }

  &__page-intro,
  &__user-data {
    @include font('barlow', 'md', 'lg');

    text-align: center;
    margin-top: spacing('6xl');
  }

  &__data-label {
    @include font('barlow', 'md', 'lg', 'medium');
  }

  &__button {
    @include font('barlow', 'md', 'lg');

    margin: spacing('6xl') auto 0;
    padding: 7px 24px 6px;
    height: 44px;
    font-size: 14px;

    &:hover:enabled {
      background-color: transparent;
      color: var(--secondary-alternate);
      border: 1px var(--secondary-alternate) solid;
    }
  }

  &__error {
    color: var(--error);
    margin-top: spacing('xl');
    text-align: center;
  }
}
