@import '../../../../../styles/import';

.cf-hero-section {
    overflow: hidden;
}

.cf-hero-blk {
    position: relative;
    height: 589px;

    .hero-img {
        position: absolute;
        width: 734px;
        bottom: -45px;
        right: -181px;

        &.img-desktop {
            display: block;
        }

        &.img-mobile {
            display: none;
        }
    }

    .ct {
        max-width: 480px;
    }

    .ttl {
        color: white;
        margin: 0;
        margin-top: 25px;
    }

    .highlight {
        color: var(--cf-pink);
    }

    @include media-breakpoint-down('xlg') {
        &.align-center {
            align-items: start;
            justify-content: center;
            height: 720px;
            padding-top: 150px;
        }

        .hero-img {
            right: 50%;
            margin-right: -367px;
            bottom: -115px;
        }

        .ct {
            text-align: center;
        }
    }

    @include media-breakpoint-down('xl') {
        .ttl {
            margin-top: 0;
        }
    }

    @include media-breakpoint-down('sm') {
        &.align-center {
            height: 815px;
            padding-top: 180px;
        }

        .ct {
            max-width: 350px;
            margin: 0 auto;

            .head-xl {
                line-height: 120%;
            }
        }

        .hero-img {
            &.img-desktop {
                display: none;
            }

            &.img-mobile {
                display: block;
                width: 362px;
                bottom: -40px;
                right: 50%;
                margin-right: -181px;
            }
        }
    }
}