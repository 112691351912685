@import '../../../styles/import';

.search-page {
    &__header {
        padding-top: spacing('xl');

        @include desktop() {
            padding-top: spacing('6xl');
        }

        .search-bar {
            visibility: hidden;
            height: 0;
        }
    }

    &__body {
        padding-bottom: spacing('xl');
        margin-top: spacing('xs');

        @include desktop() {
            padding-bottom: spacing('8xl');
        }

        .column-header {
            @include font('barlow', 'md', 'sm', 'medium');

            margin-bottom: 0;
            margin-top: spacing('2xl');

            @include desktop() {
                margin-top: 0;
            }
        }
    }

    &__featured-brands-wrapper {
        .featured-brands {
            margin-top: spacing('xs');

            @include desktop() {
                margin-right: - spacing('6xl');
            }

            @include media-breakpoint-up('lg') {
                margin-top: - spacing('lg');
            }

            &--presentation-default {
                max-width: 100%;

                @include media-breakpoint-up('xs') {
                    max-width: 70%;
                }

                @include desktop() {
                    max-width: none;
                    justify-content: normal;
                }
            }

            &__brand {
                @include desktop() {
                    width: 70px;
                    margin-right: spacing('xl');
                    margin-top: spacing('xl');
                    margin-left: 0;
                }

                @include media-breakpoint-up('lg') {
                    margin-right: spacing('6xl');
                    margin-top: spacing('6xl');
                }

                @include media-breakpoint-up('xl') {
                    width: 100px;
                }
            }

            &__logo {
                max-width: 100%;
                width: 100px;

                @include desktop() {
                    max-width: 100%;
                }
            }
        }
    }

    &__featured-deal-wrapper {
        margin-top: spacing('xs');
    }

    &__featured-deal {
        margin-top: spacing('2xl');
    }

    &__categories {
        margin-top: spacing('xs');
    }
}

.search-categories {
    margin-top: spacing('2xl');

    &__link-wrapper {
        &+& {
            margin-top: spacing('4xl');
        }
    }
}