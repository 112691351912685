@import '../../styles/import';

.checkbox-list {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;

  &__body {
    flex: 1;

    /* stylelint-disable-next-line selector-class-pattern */
    .dropdown-menu__body & {
      @include desktop() {
        margin-left: -(spacing('xl'));
        margin-right: -(spacing('xl'));
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .gradient-container__content {
        @include desktop() {
          padding-left: spacing('6xl');
          padding-right: spacing('5xl');
        }
      }
    }
  }

  &__footer {
    flex-shrink: 0;
    padding-top: spacing('2xl');
  }
}
