@import '../../styles/import';

.button {
    $selector: &;

    @include font('barlow', 'xs', 'xs', 'medium');

    border-radius: spacing('7xl');
    padding: spacing('default') spacing('lg');
    position: relative;
    display: block;
    box-sizing: border-box;
    box-shadow: $standardBoxShadow;
    cursor: pointer;
    border: none;

    &--type-primary {
        color: var(--white);
        background-color: var(--secondary-alternate);
    }

    &--type-secondary {
        color: var(--body);
        background-color: var(--white);
    }

    &--type-tertiary {
        color: var(--secondary-alternate);
        background: none;
        box-shadow: none;
    }

    &--style-rounded {
        border-radius: spacing('7xl');
    }

    &--style-square {
        border-radius: spacing('2xs');
    }

    &--display-block {
        display: block;
        width: 100%;
    }

    &--action-redirect {
        text-decoration: none;
        text-align: center;
    }

    &--emphasise {
        @include font('barlow', 'xs', 'xs', 'heavy');

        text-transform: uppercase;
    }

    &--size-large {
        @include font('barlow', 'md', 'md', 'medium');

        padding: spacing('lg') spacing('2xl');

        &#{$selector}--emphasise {
            @include font('barlow', 'md', 'md', 'heavy');
        }
    }

    &--no-box-shadow {
        box-shadow: none;
    }

    &--disabled {
        background: var(--body-light);
        cursor: not-allowed;
    }

    &--has-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        margin-right: spacing('md');
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}