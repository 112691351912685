@import '../../styles/import';

.category-modal {
  display: flex;
  flex-direction: column;
  padding: 0 spacing('2xl') spacing('6xl');
  justify-content: space-between;

  @include desktop() {
    flex-direction: row;
    padding: 0;
  }

  &__column {
    @include desktop() {
      width: 30%;
    }
  }

  &__categories {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    padding-bottom: spacing('2xs');
    margin-bottom: -spacing('2xs');

    @include media-breakpoint-down('xs') {
      margin-top: spacing('lg');
    }
  }

  &__category-link {
    @include font('barlow', 'sm', 'lg', 'heavy');

    display: flex;
    color: var(--primary);
    flex-flow: row nowrap;
    justify-content: space-between;
    text-decoration: none;
  }

  &__category {
    margin-top: spacing('3xl');

    @include desktop() {
      margin-top: spacing('lg');
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__category-chevron {
    margin-left: spacing('2xl');
    flex-shrink: 0;

    @include desktop() {
      display: none;
    }

    path {
      stroke: var(--primary);
    }
  }

  &__category-name {
    white-space: nowrap;

    @include desktop() {
      @include underline-on-hover();
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  +.modal__close-button {
    top: spacing('lg');

    @include desktop() {
      display: none;
    }
  }

  &__column-category-name {
    @include media-breakpoint-down('xs') {
      height: 2 * line-height('xl');
      padding-top: spacing('default');
      margin-left: 50px;
      margin-right: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      text-align: center;
    }
  }

  &__column-header {
    margin-top: spacing('2xl');

    @include font('barlow', 'md', 'xs', 'heavy');

    @include desktop() {
      margin-top: 0;
    }

    &--column-category {
      @include media-breakpoint-down('xs') {
        @include font('barlow', 'xxl', 'xl', 'heavy');

        margin-top: 0;
        margin-bottom: 0;
        max-height: 2 * line-height('xl');
      }
    }

    &--column-brands-deal {
      margin-bottom: spacing('xs');
      margin-top: spacing('6xl');

      @include desktop() {
        margin-top: 0;
      }
    }
  }

  &__featured-deal {
    margin-top: spacing('2xl');

    @include media-breakpoint-up('xs') {
      width: 50%;
    }

    @include desktop() {
      width: 100%;
    }
  }
}