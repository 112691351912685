.subscribe {
    text-align: center;

    &__submit {
        width: 100%;
        margin-top: 40px;
    }

    &__recaptcha {
        display: inline-block;
        margin: 25px auto 0;
    }

    &__hidden {
        display: none;
    }
}
