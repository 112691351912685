/* stylelint-disable selector-class-pattern */
@import '../../../styles/import';

.featured-brands {
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
  }

  $self: &;

  & {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  @include media-breakpoint-up('lg') {
    margin-top: -spacing('xl');
  }

  &--presentation-default {
    flex-wrap: wrap;
    justify-content: flex-start;

    @include desktop() {
      justify-content: flex-start;
    }
  }

  &__non-event {
    display: flex;
  }

  // Add a spare last item to handle the case of two items in the last row
  // with using space-between above, the two items will show on the outside
  // edges of the row, looking silly.
  &::after {
    content: '';
    width: 30%;

    @include desktop() {
      display: none;
    }
  }

  &__brand {
    margin-top: spacing('default');
    position: relative;
    width: 150px;
    height: 95px;
    background-color: var(--white);
    border: 1px solid var(--silver);
    border-radius: 4px;
    margin-right: auto;

    @include desktop() {
      width: 167.28px;
      height: 95px;
      margin-right: spacing('lg');
      margin-top: spacing('lg');
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    // Removed Brand Wall Greyscale Effect
    // filter: grayscale(100%);
    // opacity: .3;
    cursor: pointer;

    .no-touchevents &:hover {
      filter: none;
      opacity: 1;
    }
  }

  &__logo {
    max-width: 150px;
    max-height: 80px;
  }
}

.fetured-brands__carosel {
  height: 100px;

  @media screen and (min-width: 768px) {
    display: none;
    margin: 0;
  }

  &__link {
    margin: 0;
    display: inline-block;
    width: 95%;
    height: 95px;
    border: 1px solid var(--silver);
    border-radius: 4px;
    position: relative;
    margin-bottom: spacing('default');
  }

  &__logo {
    max-width: 100%;
    max-height: 80px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .slick-list {
    margin: 0 20px;
    border-radius: 10px;
  }

  .carousel__arrow {
    width: 20px;
    height: 20px;

    svg {
      height: 7px;
      width: 4px;
    }
  }

  .carousel__arrow--direction-prev {
    left: 0;
  }

  .carousel__arrow--direction-next {
    right: 0;
  }
}
