@import '../../styles/import';

.badge {
  @include font('barlow', 'md', $weight: 'medium');

  color: var(--primary);
  background: var(--primary-transparent);
  height: 24px;
  padding: 0 spacing('default');
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius--small;
}