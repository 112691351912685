@import '../../styles/import';

.category-refinement {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__dropdown {
        padding: spacing('default');

        & + & {
            margin-left: spacing('lg');
        }
    }
}
