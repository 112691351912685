@import '../../styles/import';

.carousel {
  // To allow the arrows to be centred over the image, the image itself must be the whole
  // height of the container. But we need the dots to be underneath the container, and they
  // are positioned within the container in the markup.
  // The solution here is to have the dots position absolute, and use margin to give the
  // whitespace below the component for them to fit in. For an example of this in practice,
  // see: https://react-slick.neostack.com/docs/example/simple-slider.
  // The specific height below is the gap between the dots and the image of the carousel,
  // plus the height of the dots themselves.
  $dot-size: 12px;
  $dots-spacing-mobile: spacing('lg') + $dot-size;
  $dots-spacing-desktop: spacing('3xl') + $dot-size;

  margin-bottom: $dots-spacing-mobile;
  margin-top: spacing('xl');

  @include desktop() {
    margin-top: spacing('6xl');
    margin-bottom: $dots-spacing-desktop;
  }

  &__dots {
    position: absolute;
    bottom: -$dots-spacing-mobile;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex !important; // !important to override inline styles from react-slick
    flex-direction: row;
    justify-content: center;

    @include desktop() {
      bottom: -$dots-spacing-desktop;
    }

    li {
      width: 50px;
      height: 8px;
      border-radius: 50px;
      background: var(--shadow);
      margin-right: spacing('md');

      @media screen and (max-width: 768px) {
        width: 30px;
        height: 4.8px;
        border-radius: 30px;
      }
    }

    @each $theme in $themes {
      &__#{$theme} {
        li {
          &.slick-active {
            background-color: var(--event-#{$theme}-carousel-slider-icon-color);
          }
        }
      }
    }
  }

  &__dot {
    width: 100%;
    height: 100%;
  }

  &__arrow,
  &__arrow-alt {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: z-index('above-page-content');
    border-radius: 50%;
    background: var(--dark-shadow);
    cursor: pointer;
    width: 20px;
    height: 20px;

    @include desktop() {
      width: 60px;
      height: 60px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 7px;
      width: 4px;

      @include desktop() {
        height: 21px;
        width: 11px;
      }
    }

    path {
      stroke: var(--white);
    }

    &--direction-prev {
      left: spacing('default');

      @include desktop() {
        left: spacing('2xl');
      }

      svg {
        transform: translate(-50%, -50%) scaleX(-1);
      }
    }

    &--direction-next {
      right: spacing('default');

      @include desktop() {
        right: spacing('2xl');
      }
    }
  }

  &__arrow-search {
    position: absolute;
    top: 30%;
    transform: translateX(840%);
    z-index: z-index('above-page-content');
    border-radius: 50%;
    background: var(--dark-shadow);
    cursor: pointer;
    width: 20px;
    height: 20px;

    @include desktop() {
      width: 30px;
      height: 30px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 7px;
      width: 4px;

      @include desktop() {
        height: 21px;
        width: 11px;
      }
    }

    path {
      stroke: var(--white);
    }

    &--direction-prev-search {
      left: spacing('default');
      transform: translateX(-150%);

      @include desktop() {
        left: spacing('2xl');
        transform: translateX(-150%);
      }

      svg {
        transform: translate(-50%, -50%) scaleX(-1);
      }
    }

    &--direction-next-search {
      right: spacing('default');
      transform: translateX(150%);

      @include desktop() {
        right: spacing('2xl');
      }
    }
  }

  &__arrow-alt {
    background: transparent;
    top: 100px;

    svg {
      height: 32px;
      width: 16px;
    }

    &--direction-prev {
      left: spacing('6xl');
    }

    &--direction-next {
      right: spacing('6xl');
    }
  }
}
