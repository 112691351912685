@import '../../styles/import';

.main-countdown {
  &__timer-container {
    // background-color: #2e3192;
    margin-top: 32px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    column-gap: 22px;
    align-items: center;

    // color: var(--white);
    font-size: 24px;
    line-height: 28px;
    padding: 9px;

    @each $theme in $themes {
      &.countdown-#{$theme} {
        background-color: var(--event-#{$theme}-countdowntimer-container-background-color);
        color: var(--event-#{$theme}-countdown-text-color);

        @if $theme =='paypal' {

          @font-face {
            font-family: PayPalPro;
            src: local('PayPalPro'), url('../../static/fonts/PayPalPro.otf') format('opentype');
          }

          /* stylelint-disable-next-line */
          font-family: PayPalPro;
        }
      }
    }

    @media screen and (max-width: 959px) {
      font-size: 15px;
      line-height: 19px;
      padding: 7px;
      column-gap: 5px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: row;
      row-gap: 10.5px;
      margin-right: 12px;
      margin-left: 12px;
    }

    @media screen and (max-width: 481px) {
      font-size: 15px;
      line-height: 19px;
      padding: 7px;
      column-gap: 5px;
    }

    @media screen and (max-width: 380px) {
      font-size: 12px;
      padding: 5px;
      column-gap: 3px;
    }
  }

  &__timer-content {
    display: flex;
    justify-content: space-evenly;
    column-gap: 10px;

    @media screen and (max-width: 959px) {
      column-gap: 7px;
    }
  }

  &__timer-item {
    // background-color: #92278f;
    padding: 18px 0;
    width: 7vw;
    min-width: 107px;
    text-align: center;
    border-radius: 8px;

    @each $theme in $themes {
      &.countdown-#{$theme} {
        background-color: var(--event-#{$theme}-countdowntimer-background-color);
        color: var(--event-#{$theme}-countdowntimer-text-color);
      }
    }

    @media screen and (max-width: 1200px) {
      width: 7.5vw;
    }

    @media screen and (max-width: 959px) {
      padding: 9px 0;
      min-width: 70px;
    }

    @media screen and (max-width: 480px) {
      font-size: 15px;
    }

    @media screen and (max-width: 374px) {
      min-width: 65px;
    }
  }

  &__timer-number {
    margin-right: 6px;
  }

  &__timer-logo {
    height: 37px;

    @media screen and (max-width: 959px) {
      height: 25px;
    }

    .logo-image {
      height: inherit;
    }
  }

  &__timer-text {
    &__desktop {
      @media screen and (max-width: 511px) {
        display: none;
      }
    }

    &__mobile {
      @media screen and (min-width: 512px) {
        display: none;
      }
    }
  }
}