.sponsored-deals {
  display: flex;

  @media screen and (max-width: 768px) {
    margin-left: 12px;

    .sponsored-deal-card {
      margin: 0 6px;
    }
  }

  @media screen and (min-width: 769px) {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    place-items: center center;
    gap: 0 12px;
  }
}
