@import '../../styles/import';

.cf-events-countdown {
    padding: spacing('7xl') 0;
    text-align: center;

    @include media-breakpoint-down('xs') {
        padding: 0;
    }

    .row {
        @include media-breakpoint-down('xs') {
            margin-left: 0;
            margin-right: 0;

            & > div {
                padding: 0;
            }
        }

        & > div:nth-child(1),
        & > div:nth-child(3) {
            @include media-breakpoint-up('md') {
                margin-top: spacing('7xl');
            }
        }

        > div:nth-child(2) {
            @include media-breakpoint-up('md') {
                margin-bottom: spacing('7xl');
            }
        }
    }

    &__card {
        box-sizing: border-box;
        flex: 1;

        @include media-breakpoint-up('md') {
            height: 100%;
        }
    }
}
