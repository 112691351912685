@import '../../../styles/import';

.category-nav {
    width: 100%;
    background-color: var(--white);
    max-height: 75vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }

    &__container-title {
        font-size: 28px;
        line-height: 28px;
        font-weight: 600;

        @media screen and (max-width: 1200px) {
            font-size: 24px;
            line-height: 24px;
        }
    }

    &__menu-deal-container {
        padding-top: 34px;
    }

    &__main-menu-container, &__sub-menu-container, &__deal-container {
        padding-top: 25px;
    }

    &__brands-container {
        padding-top: 25px;
        width: 100%;
        row-gap: 15px;
    }

    &__brands-section {
        padding-top: 10px;
        padding-bottom: 35px;

        @media screen and (max-width: 992px) {
            padding-bottom: 25px;
        }
    }

    &__brand-wrapper {
        display: flex;
        align-items: center;
        column-gap: 10px;

        @media screen and (max-width: 992px) {
            padding-bottom: 10px;
        }
    }

    &__main-menu-list, &__sub-menu-list {
        font-size: 18px;
        line-height: 36px;
        font-weight: 500;
        list-style: none;
        padding: 0;

        @media screen and (max-width: 1200px) {
            font-size: 16px;
            line-height: 32px;
        }
    }

    &__main-menu-item, &__sub-menu-item {
        cursor: pointer;
        color: var(--black);

        &:hover {
            font-weight: 600;
        }

        &.is-active {
            font-weight: 600;
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }
    }

    &__brand-name {
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
        color: var(--black);
    }

    &__brand-logo {
        height: 60px;
        width: 60px;
        object-fit: contain;
        border: 1px solid #ddd;
        border-radius: 16px;
    }
}
