@import '../../../styles/import';

.advertise-here {
    display: flex;
    justify-content: center;
    background: var(--dark-purple);
    text-align: center;
    padding: 20px 0;

    // @each $theme in $themes {
    //     &--#{$theme} {
    //         color: var(--event-#{$theme}-secondary-text-colour);
    //         background: var(--event-#{$theme}-secondary-background-colour);
    //     }
    // }

    &__link {
        display: flex;
        color: var(--white);
        text-decoration: none;
        text-transform: uppercase;

        @include font('barlow', 'md', 'sm', 'heavy');

        &:hover {
            color: var(--white);
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }

        // @each $theme in $themes {
        //     .advertise-here--#{$theme} & {
        //         color: var(--event-#{$theme}-secondary-text-colour);
        //     }
        // }
    }

    &__icon {
        margin-right: spacing('default');
        height: 20px;

        path {
            fill: var(--white);
        }

        // @each $theme in $themes {
        //     .advertise-here--#{$theme} & {
        //         path {
        //             fill: var(--event-#{$theme}-secondary-text-colour);
        //         }
        //     }
        // }
    }
}