@import '../../styles/import';

.checkbox {
  $self: &;

  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  &--type-field {
    overflow: visible;
  }

  &+& {
    margin-top: spacing('4xl');
  }

  &--alignment-center {
    justify-content: flex-start;
    align-items: flex-start;

    @include desktop() {
      justify-content: center;
    }
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  &--type-field {
    margin-top: spacing('xl');

    @include desktop() {
      margin-top: spacing('2xl');
    }
  }

  &__label {
    @include font('barlow', 'md', $weight: 'regular');

    #{$self}--alignment-left & {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
    }

    #{$self}--type-field & {
      line-height: line-height('lg');
    }
  }

  &__icon {
    margin-right: spacing('xl');
    margin-top: spacing('xs');
  }

  &__icon svg {
    display: block;
  }

  &__icon svg path {
    opacity: 0.2;

    #{$self}--checked & {
      opacity: 1;
      stroke: var(--primary);
    }
  }

  &__icon svg rect,
  &__icon svg path {
    #{$self}--error & {
      stroke: var(--error);
    }
  }

  &__input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
  }

  &__error-message {
    @include font('barlow', 'xs', 'xs');

    display: block;
    color: var(--error);
  }
}