@import '../../../styles/import';
/* stylelint-disable */
@import './typed.scss';
/* stylelint-enable */

.loading-overlay {
  position: fixed;
  inset: 0;

  // background: var(--white);
  // background-image: url('../../../static/images/loading_bg.jpg');
  // background-image: url('../../../static/images/CF-Thrive-BACKGROUND-2.png');
  background-image: url('../../../static//images/generic-loading-background.webp');
  background-repeat: no-repeat;
  background-size: cover;

  // background-color: #92278F;
  // background-color: #3c1f4d;
  background-color: #838787;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: z-index(overlay);

  // &__text {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  // }

  &__logo {
    width: 300px;

    @media screen and (max-width: 425px) {
      width: 200px;
    }

    @media screen and (max-width: 350px) {
      width: 150px;
    }
  }

  &__text {
    text-align: center;
    font-size: 30px;
    color: var(--white);
    @include typed('Loading up some awesome frenzy deals, get ready to click!',
      (type: 0.05,
        pause-typed: 0.5,
        delete: 0.03,
        pause-deleted: 0.5,
      ),
      (iterations: 0,
        caret-width: 3px,
      ));

    @media screen and (max-width: 425px) {
      font-size: 20px;
    }

    @media screen and (max-width: 350px) {
      font-size: 15px;
    }
  }
}