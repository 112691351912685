.sponsor-bubble {
    &__partner-wrapper {
        height: 72px;
        position: relative;
        left: -10px;
        line-height: normal;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1200px) {
            height: 56px;
            left: -7px;
        }

        @media screen and (max-width: 992px) {
            height: 44px;
        }
    }

    &__partner-container {
        height: 40px;
        width: 98px;
        background-size: contain;
        background-repeat: no-repeat;
        color: var(--white);
        font-size: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        border-radius: 4px;

        @media screen and (max-width: 1200px) {
            height: 30px;
            width: 78px;
            font-size: 8.5px;
        }

        @media screen and (max-width: 389px) {
            width: 68px;
        }

        @media screen and (max-width: 370px) {
            width: 65px;
            margin-left: 5px;
        }
    }

    &__partner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    &__partner-logo {
        width: 100%;
        max-width: 96px;
        padding: 3px;

        // @media screen and (max-width: 1200px) {
        //   height: 26px;
        // }
    }

    &__partner-text {
        position: absolute;
        /* stylelint-disable-next-line length-zero-no-unit */
        top: 0px;

        @media screen and (max-width: 992px) {
            top: -5px;
        }
    }
}