@import '../../../styles/import';


.cell {
    margin-top: 30px;
    margin-bottom: 40px;

    @media screen and (max-width: 1200px) {
        margin-top: 25px;
        margin-bottom: 35px;
    }

    @media screen and (max-width: 960px) {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
        margin-left: 12px;
        margin-top: 15px;
        margin-bottom: 25px;
    }
}

.categories {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    column-gap: 12px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }
}

.item {
    white-space: nowrap;
}

.button {
    background-color: var(--purple);
    height: 48px;
    border-radius: 50px;
    font-family: font-family('barlow');
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    padding-right: 35px;
    padding-left: 35px;

    @media screen and (max-width: 768px) {
        height: 44px;
        font-size: 14px;
        line-height: 24px;
        padding-right: 30px;
        padding-left: 30px;
    }
}