@import '../../../styles/import';

.mobile-nav {
  @include z-index('layout-header');

  position: relative;

  &__body {
    @include z-index('above-layout-header');

    position: relative;
  }

  &__header {
    &-container {
      background-color: var(--white);
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: var(--purple);
      display: flex;
      height: 77px;
      justify-content: space-between;
      align-items: center;
    }

    &-heading {
      margin-left: 20px;
    }

    &-icon {
      margin-right: 30px;
      cursor: pointer;
    }
  }

  &__menu {
    &-section {
      min-height: 100vh;
      max-width: 90vw;
      max-height: 100vh;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
      }
    }

    &-container {
      background-color: var(--white);
      min-height: 100vh;
    }

    &-main {
      &-list {
        .list-item {
          height: 53px;
          display: flex;
          border-bottom: 1px solid #eee;
          padding-left: 18px;
          align-items: center;
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          cursor: pointer;

          .item-text {
            padding-left: 10px;
          }
        }

        .list-item-link {
          color: var(--black);
        }
      }

      &-action {
        .signup-cta {
          display: flex;
          column-gap: 2px;
          margin-top: 63px;
          justify-content: center;
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 16.5px;

          .text {
            font-weight: 400;
          }

          .link {
            font-weight: 600;
            color: var(--black);
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .login-cta {
          /* stylelint-disable-next-line custom-property-pattern */
          background-color: var(--event-goWild-secondary-colour);
          width: 277px;
          height: 44px;
          margin: 0 auto;
          color: var(--white);
          font-size: 14px;
          line-height: 16.5px;
          font-weight: 600;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 10px;
          cursor: pointer;

          @each $theme in $themes {
            &.mobile-nav-#{$theme} {
              background-color: var(--event-#{$theme}-login-cta-background-color);
            }
          }

          .login-cta-icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 25px;
          }

          .login-cta-icon .icon-top {
            width: 12px;
            height: 12px;
            border: 2px solid var(--white);
            border-radius: 50%;
          }

          .login-cta-icon .icon-bottom {
            width: 20px;
            height: 10px;
            border-top: 2px solid var(--white);
            border-left: 2px solid var(--white);
            border-right: 2px solid var(--white);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            margin-top: 2px;
          }
        }
      }
    }

    &-category-main,
    &-category-sub {
      padding-left: 20px;

      .back-button {
        height: 53px;
        width: 80px;
        display: flex;
        align-items: center;
        column-gap: 20px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        cursor: pointer;
      }

      .list {
        list-style: none;
        padding: 0;
        font-size: 16px;
        line-height: 48px;
        font-weight: 500;

        .list-item {
          cursor: pointer;

          &:hover {
            font-weight: 600;
          }
        }
      }
    }
  }

  &__brand-image {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border: 1px solid #ddd;
    border-radius: 12px;
  }

  &__brand-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 28px;
    font-weight: 500;
    column-gap: 12px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__featured-brands,
  &__featured-deal {
    margin-top: 40px;

    .title {
      font-size: 16px;
      line-height: 48px;
      font-weight: 600;
    }
  }
}
