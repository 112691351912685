/** TYPO **/
@mixin font-sharpgrotesk-bold() {
    font-family: "SharpGroteskSmBold20", Arial, Helvetica, sans-serif;
    font-weight: normal;
    line-height: 150%;
}

@mixin font-barlow-bold() {
    font-family: Barlow, Arial, Helvetica, sans-serif;
    font-weight: normal;
    line-height: 150%;
}

.font-sg-bold {
    @include font-sharpgrotesk-bold();
}

.font-barlow-bold {
    @include font-barlow-bold();
}

.font-xs {
    font-size: 12px;
}

.font-sm {
    font-size: 14px;
}

.font-md {
    font-size: 16px;
}

.font-lg {
    font-size: 18px;
}

/* HEADING */
.head-xl {
    @include font-barlow-bold();

    font-size: 36px;
    letter-spacing: 1px;
}

.head-lg {
    @include font-barlow-bold();

    font-size: 28px;
}

.head-md {
    @include font-barlow-bold();

    font-size: 24px;
}

.head-sm {
    @include font-barlow-bold();

    font-size: 18px;
}