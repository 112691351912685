@import '../../styles/import';

.profile-page {
  &__title {
    @include font('barlow', 'xxl', 'xl', 'medium');

    margin-top: spacing('3xl');

    @include desktop() {
      margin-top: spacing('6xl');
    }
  }

  &__section {
    display: table;
    margin-top: spacing('2xl');

    &--secondary {
      margin-top: 0;

      @include desktop() {
        margin-top: spacing('2xl');
      }
    }
  }

  &__row {
    display: table-row;
  }

  &__label,
  &__value {
    @include font('barlow', 'md', 'sm');

    display: table-cell;
    padding-top: spacing('2xl');

    &--narrow {
      padding-top: 0;
    }
  }

  &__value {
    input:read-only {
      background: var(--light-grey);
    }
  }

  &__label {
    min-width: 120px;
    color: var(--clear-grey);
  }

  &__button {

    /* stylelint-disable-line */
    &--signout,
    &--edit,
    &--save,
    &--cancel,
    &--upload,
    &--presale {
      width: 100%;
      margin: 5px 0;

      @include desktop() {
        margin-top: spacing('6xl');
      }
    }

    &--edit {
      margin-top: spacing('4xl');

      @include desktop() {
        margin-top: spacing('6xl');
      }
    }

    &--editpass,
    &--send,
    &--verify {
      border: 1px solid var(--secondary-alternate);
      margin-top: 36px;
    }

    &--editpass {
      margin-left: auto;
      margin-right: auto;
    }

    &--send,
    &--verify,
    &--verified {
      margin-left: spacing('md');
    }

    &--verify {
      margin-top: 0;
    }

    &--verified {
      border: 1px solid var(--green);
      color: var(--green);
      margin-top: 36px;
      background: transparent;
    }

    &--presale {
      @media screen and (min-width: 480px) {
        margin-top: spacing('4xl');
      }

      @include desktop() {
        margin-top: spacing('6xl');
      }

      &:hover {
        color: var(--white);
      }
    }

    &--upload {
      &:hover {
        color: var(--white);
      }
    }

    &--signout,
    &--edit {
      width: 50%;
      margin: 25px 0;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 5px 0;
      }
    }
  }

  &__alert {
    color: var(--white);
    background-color: var(--error);
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid var(--error);
    border-radius: 0.25rem;
  }

  &__address {

    /* stylelint-disable-next-line selector-class-pattern */
    .profile-page__label {
      padding-top: spacing('7xl');
    }
  }
}