/* stylelint-disable selector-class-pattern */
@import '../../styles/import';

@each $theme in $themes {
  .footer-#{$theme} {
    @if $theme =='travel' {
      background-image: var(--event-#{$theme}-footer-background-image);
    }

    @else {
      background-color: var(--event-#{$theme}-footer-background-color);
    }
  }

  .footer-social-#{$theme} {
    background-color: var(--event-#{$theme}-footer-social-icon-background-color);

    svg {
      fill: var(--event-#{$theme}-footer-social-icon-color);
    }
  }

  .footer-social-#{$theme}:hover {
    background-color: var(--event-#{$theme}-footer-social-icon-hover-background-color);

    svg {
      fill: var(--event-#{$theme}-footer-social-icon-hover-color);
    }
  }
}

.footerBody {
  color: var(--white);
  font-family: font-family('barlow');
}

.footerTop {
  padding-top: 45px;
  padding-bottom: 80px;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.logoImage {
  height: 60px;
  margin-right: 5px;

  @media screen and (max-width: 1200px) {
    height: 56px;
  }

  @media screen and (max-width: 992px) {
    height: 52px;
  }
}

.partnerWrapper {
  height: 72px;
  position: relative;
  left: -10px;

  @media screen and (max-width: 1200px) {
    height: 56px;
    left: -7px;
  }

  @media screen and (max-width: 992px) {
    height: 44px;
  }
}

.partnerContainer {
  height: 49px;
  width: 98px;
  background-size: contain;
  background-repeat: no-repeat;
  color: var(--white);
  font-size: 11px;

  @media screen and (max-width: 1200px) {
    height: 39px;
    width: 78px;
    font-size: 8.5px;
  }
}

.partnerContent {
  position: relative;
  top: 8%;
  left: 8%;

  @media screen and (max-width: 1200px) {
    left: 11%;
  }
}

.partnerLogo {
  height: 16px;
  margin-top: -4px;
  margin-left: 8px;

  @media screen and (max-width: 1200px) {
    height: 13px;
    margin-top: -12px;
    margin-left: 4px;
  }
}

.eventLogo {
  display: flex;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
}

.menuList {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 50px !important;
  margin-right: 50px !important;

  @media screen and (max-width: 1200px) {
    font-size: 20px;
    line-height: 38px;
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  @media screen and (max-width: 1200px) {
    font-size: 18px;
    line-height: 32px;
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  @media screen and (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.itemLink {
  color: var(--white);
  text-decoration: none;

  &:hover {
    color: var(--white);
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
}

.socialActionsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 60%;
}

.socialActionsTrademark {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: small;
  text-align: center;
}

.socialActions {
  display: flex;
  column-gap: 10px;
  justify-content: center;
}

.socialIcons {
  color: var(--white);
  text-decoration: none;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  // &:hover {
  //   color: var(--white);
  //   background-color: var(--secondary-alternate-bright);
  // }
}

.menuItem {
  margin-bottom: 5px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.copyrightText {
  font-size: 14px;
  line-height: 16.5px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.footerBottom {
  padding-bottom: 45px;
}