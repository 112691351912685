/* stylelint-disable selector-class-pattern */
@import '../../../styles/import';

.brand-detail-page {
  &.container {
    margin: 0 auto;
  }

  .full-width-backdrop {
    // background: #00aeef;
    height: 200px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    @each $theme in $themes {
      &.brand-detail-#{$theme} {
        background-color: var(--event-#{$theme}-brand-page-backdrop-color);
      }
    }
  }

  // margin: 0;

  .breadcrumb-container {
    z-index: z-index('above-page-content');
    margin-top: 1em;

    svg {
      stroke: var(--white);
    }
  }

  .brand-detail-page-info-cont {
    &__brand-info {
      margin-top: -100px;
    }

    .brand-detail-basic__primary {
      .brand-detail-basic__logo-wrapper {
        box-shadow: rgb(0 0 0 / 12%) 0 1px 3px, rgb(0 0 0 / 24%) 0 1px 2px;
        border-radius: 16px;
        height: 188px;
        width: 188px;
        background: var(--white);

        .brand-detail-basic__logo-image {
          max-width: 90%;
        }
      }

      .brand-detail-basic__name-wrapper {
        margin-top: 2em;

        @include desktop() {
          margin-top: 5em;
        }
      }
    }
  }
}

.brand-detail-page-breadcrumb {
  margin-bottom: spacing('3xl');
  padding: 2em 0;

  li {
    span {
      color: var(--white);
    }
  }
}
