@import '../../styles/import';

.cf-countdown-background {
    @each $theme in $themes {
        &--#{$theme} {
            background: var(--event-#{$theme}-background-colour);
            color: var(--event-#{$theme}-text-colour);
            background-repeat: repeat !important;

            // background-size: contain !important;

            .cf-countdown-timer {
                &__time {
                    color: var(--event-#{$theme}-countdown-text-colour);
                    background-color: var(--event-#{$theme}-secondary-alternate-transparent);
                }
            }
        }
    }
}

.cf-countdown {
    display: flex;
    flex-direction: column;
    padding: spacing('xl') spacing('lg');
    margin: 0 auto;
    align-items: center;
    text-align: center;

    @include media-breakpoint-up('lg') {
        padding: spacing('xl') 30px; // 100px
    }

    @include media-breakpoint-up('xl') {
        padding: spacing('xl') 120px; // 170px
    }

    &__logos {
        padding-top: spacing('8xl');
    }

    &__logo img {
        display: block;
        max-width: 100%;
        height: auto;
        width: 500px;
    }

    &__logo {
        margin: spacing('md') 0;
    }

    &__logo-description {
        color: var(--white);
    }

    &__button {
        margin-bottom: spacing('xl');
        margin-top: spacing('xl');

        &--advertise {
            margin-top: spacing('xl') + 32px;
        }

        @each $theme in $themes {
            a {
                border-width: 2px;
                max-width: 100%;
                background-color: var(--event-#{$theme}-background-colour);
                border-color: var(--event-#{$theme}-secondary-colour);

                &:hover,
                &:active,
                &:focus {
                    background-color: var(--event-#{$theme}-secondary-background-colour);
                    border-color: var(--event-#{$theme}-secondary-colour);
                }
            }
        }
    }

    &__link {
        display: table;
        margin: auto;
        color: var(--white);
        text-align: center;
        align-items: center;
        height: 100%;
    }

    &__icon {
        padding-right: spacing('default');
        height: 20px;
        display: table-cell;
        vertical-align: middle;

        @each $theme in $themes {
            .cf-countdown__link--#{$theme} & {
                path {
                    fill: var(--event-#{$theme}-secondary-colour);
                }
            }
        }
    }

    &__text {
        @include font('barlow', 'xxs', 'xxs', 'heavy');

        text-decoration: none;
        text-transform: uppercase;
        display: table-cell;
        vertical-align: middle;

        @each $theme in $themes {
            .cf-countdown__link--#{$theme} & {
                color: var(--event-#{$theme}-secondary-text-colour);
            }
        }

        @include media-breakpoint-up('lg') {
            @include font('barlow', 'xs', 'xs', 'heavy');
        }

        @include media-breakpoint-up('xl') {
            @include font('barlow', 'sm', 'sm', 'heavy');
        }
    }

    &__copy {
        margin: spacing('md') 0;

        @include media-breakpoint-up('md') {
            padding: 0 spacing('6xl');
        }

        @include media-breakpoint-up('xl') {
            padding: 0 spacing('9xl');
        }
    }

    &__arrow {
        margin: spacing('4xl') 0 0;
    }

    &__videoResponsive {
        width: 100%;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
        margin-bottom: spacing('xl');

        iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }
}