/* stylelint-disable custom-property-pattern */
:root {
  --ant-blue: #40a9ff;
  --black: #000;
  --body-background: #f2f2f2;
  --body-light: #666;
  --body: #414141;
  --border: #dcdcdc;
  --bright-grey: #f8f8f8;
  --clear-grey: #bebebe;
  --dark-shadow: rgb(0 0 0 / 16%);
  --error: #ff5c00;
  --facebook: #3a5ca9;
  --gold: #d4af37;
  --google: #397af2;
  --hover-shadow: #cccaca;
  --input-border: #d9d9d9;
  --ivory: #f1f1f1;
  --light-grey: #ececec;
  --overlay: rgb(0 0 0 / 20%);
  --primary-transparent: rgb(0 163 255 / 10%);
  --primary: #2196f3;
  --red-fade: #ff7875;
  --red: #df3a3a;
  --secondary-alternate-bright: #a320a0;
  --secondary-alternate-transparent: rgb(147 32 145 / 10%);
  --secondary-alternate: #932091;
  --secondary-black: #2c1946;
  --secondary-dark: #391d60;
  --secondary-heavy: #3c225f;
  --secondary-light: #533281;
  --secondary: #4a277a;
  --shadow: rgb(0 0 0 / 20%);
  --sidenav-active: rgb(33 150 243 / 10%);
  --sidenav-subnav-active: rgb(33 150 243 / 5%);
  --silver: #c0c0c0;
  --status-approved: #6fcf97;
  --status-declined: #df3a3a;
  --status-expired: #df3a3a;
  --status-live: #50c462;
  --status-open: #00a3ff;
  --status-review: #f2994a;
  --status-unknown: #999;
  --status-upcoming: #2196f3;
  --white-shadow: rgb(255 255 255 / 6%);
  --white: #fff;
  --purple: #5c2d91;
  --darkPurple: #441a74;
  --text-gray: #444;
  --text-light-gray: #666;
  --light-gray-background: #f5f5f5;
  --neon-pink: #ebbee0;
  --neon-blue: #a0c9e4;
  --glow-pink: #ed008c;
  --border-purple: #8c29ae;

  // Go Wild - The Main Event
  --event-goWild-deal-card-cta-btn-background-color: #0095da;
  --event-goWild-cta-banner-title-text-color: var(--black);
  --event-goWild-cta-banner-description-text-color: var(--black);
  --event-goWild-home-header-background-color: #f5f5f5;
  --event-goWild-hamburger-menu-background-colour: #0095da;
  --event-goWild-header-colour: #5c2d91;
  --event-goWild-background-colour: #5c2d91;
  --event-goWild-custom-background-colour: #fff;
  --event-goWild-secondary-background-colour: #bd1a8d;
  --event-goWild-third-background-colour: #5c2d91;
  --event-goWild-text-colour: #fff;
  --event-goWild-secondary-text-colour: #fff;
  --event-goWild-secondary-colour: #bd1a8d;
  /* stylelint-disable-next-line */
  --event-goWild-secondary-alternate-transparent: hsla(0, 0%, 100%, 0.3);
  --event-goWild-secondary-alternate: #bd1a8d;
  --event-goWild-countdown-text-color: #fff;
  --event-goWild-countdowntimer-text-color: #fff;
  --event-goWild-top-banner-background-colour: #ff6900;
  --event-goWild-breadcrumb-chevron-stroke-color: #828282;
  --event-goWild-login-cta-background-color: #bd1a8d;
  --event-goWild-login-cta-text-color: #fff;
  --event-goWild-login-cta-avatar-color: #fff;
  --event-goWild-signup-cta-text-color: #000;
  --event-goWild-brand-page-backdrop-color: #bd1a8d;
  --event-goWild-brand-detail-additional-icon-color: #0095da;
  --event-goWild-second-nav-item-icon-color: #bd1a8d;
  --event-goWild-nav-background-color: #fff;
  --event-goWild-second-nav-background-color: #0095da;
  --event-goWild-second-nav-text-color: #fff;
  --event-goWild-logged-in-avatar-background-color: #e0a3df;
  --event-goWild-footer-background-color: #2e2e2e;
  --event-goWild-cta-banner-button-background-color: #bd1a8d;
  --event-goWild-cta-banner-button-text-color: #fff;
  --event-goWild-countdowntimer-container-background-color: #0095da;
  --event-goWild-countdowntimer-background-color: #bd1a8d;
  --event-goWild-footer-social-icon-background-color: #bd1a8d;
  --event-goWild-footer-social-icon-hover-background-color: var(--secondary-alternate-bright);
  --event-goWild-threeColImage-background-color: #0095da;
  --event-goWild-threeColImage-button-background-color: #bd1a8d;
  --event-goWild-threeColImage-button-text-color: #fff;
  --event-goWild-threeColImage-text-color: #fff;
  --event-goWild-carousel-slider-icon-color: #bd1a8d;
  --event-goWild-searchbar-placeholder-text-color: #bebebe;
  --event-goWild-searchbar-placeholder-mobile-text-color: #bebebe;
  --event-goWild-searchbar-icon-stroke-color: #f2f2f2;
  --event-goWild-searchbar-icon-mobile-stroke-color: #f2f2f2;

  // Mayhem
  --event-mayhem-deal-card-cta-btn-background-color: #0095da;
  --event-mayhem-cta-banner-title-text-color: var(--black);
  --event-mayhem-cta-banner-description-text-color: var(--black);
  --event-mayhem-home-header-background-color: #f5f5f5;
  --event-mayhem-hamburger-menu-background-colour: #2e3192;
  --event-mayhem-header-colour: #2e3192;
  --event-mayhem-background-colour: #00a3e6;
  --event-mayhem-custom-background-colour: #00a3e6;
  --event-mayhem-secondary-background-colour: #fff200;
  --event-mayhem-third-background-colour: #00a3e6;
  --event-mayhem-text-colour: #fff;
  --event-mayhem-secondary-text-colour: #2e3192;
  --event-mayhem-secondary-colour: #2e3192;
  --event-mayhem-secondary-alternate-transparent: rgb(255 255 255 / 30%);
  --event-mayhem-secondary-alternate: #fff200;
  --event-mayhem-countdown-text-color: #fff200;
  --event-mayhem-countdowntimer-text-color: #fff200;
  --event-mayhem-top-banner-background-colour: #ff6900;
  --event-mayhem-breadcrumb-chevron-stroke-color: #2e3192;
  --event-mayhem-login-cta-background-color: #fff200;
  --event-mayhem-login-cta-text-color: #2e3192;
  --event-mayhem-login-cta-avatar-color: #2e3192;
  --event-mayhem-signup-cta-text-color: #fff;
  --event-mayhem-brand-page-backdrop-color: #00aeef;
  --event-mayhem-brand-detail-additional-icon-color: #00aeef;
  --event-mayhem-second-nav-item-icon-color: #fff200;
  --event-mayhem-nav-background-color: #92278f;
  --event-mayhem-second-nav-background-color: #2e3192;
  --event-mayhem-second-nav-text-color: #fff;
  --event-mayhem-logged-in-avatar-background-color: #fff200;
  --event-mayhem-footer-background-color: #2e3192;
  --event-mayhem-cta-banner-button-background-color: #fff200;
  --event-mayhem-cta-banner-button-text-color: #2e3192;
  --event-mayhem-countdowntimer-container-background-color: #2e3192;
  --event-mayhem-countdowntimer-background-color: #92278f;
  --event-mayhem-footer-social-icon-background-color: #00aeef;
  --event-mayhem-footer-social-icon-hover-background-color: var(--secondary-alternate-bright);
  --event-mayhem-threeColImage-background-color: #92278f;
  --event-mayhem-threeColImage-button-background-color: #fff200;
  --event-mayhem-threeColImage-button-text-color: #2e3192;
  --event-mayhem-threeColImage-text-color: #fff;
  --event-mayhem-carousel-slider-icon-color: #00aeef;
  --event-mayhem-searchbar-placeholder-text-color: #bebebe;
  --event-mayhem-searchbar-placeholder-mobile-text-color: #bebebe;
  --event-mayhem-searchbar-icon-stroke-color: #f2f2f2;
  --event-mayhem-searchbar-icon-mobile-stroke-color: #f2f2f2;

  // Julove
  --event-julove-deal-card-cta-btn-background-color: #0095da;
  --event-julove-cta-banner-title-text-color: var(--black);
  --event-julove-cta-banner-description-text-color: var(--black);
  --event-julove-home-header-background-color: #f5f5f5;
  --event-julove-hamburger-menu-background-colour: #f2748a;
  --event-julove-header-colour: #f2748a;
  --event-julove-background-colour: #602d91;
  --event-julove-custom-background-colour: #602d91;
  --event-julove-secondary-background-colour: #008ad0;
  --event-julove-third-background-colour: #602d91;
  --event-julove-text-colour: #fff;
  --event-julove-secondary-text-colour: #fff;
  --event-julove-secondary-colour: #f2748a;
  --event-julove-secondary-alternate-transparent: rgb(255 255 255 / 30%);
  --event-julove-secondary-alternate: #008ad0;
  --event-julove-countdown-text-color: #fff;
  --event-julove-countdowntimer-text-color: #fff;
  --event-julove-top-banner-background-colour: #ff6900;
  --event-julove-breadcrumb-chevron-stroke-color: #008ad0;
  --event-julove-login-cta-background-color: #00aeef;
  --event-julove-login-cta-text-color: #fff;
  --event-julove-login-cta-avatar-color: #fff;
  --event-julove-signup-cta-text-color: #fff;
  --event-julove-brand-page-backdrop-color: #00aeef;
  --event-julove-brand-detail-additional-icon-color: #00aeef;
  --event-julove-second-nav-item-icon-color: #00aeef;
  --event-julove-nav-background-color: #f2748a;
  --event-julove-second-nav-background-color: #008ad0;
  --event-julove-second-nav-text-color: #fff;
  --event-julove-logged-in-avatar-background-color: #00aeef;
  --event-julove-footer-background-color: #008ad0;
  --event-julove-cta-banner-button-background-color: #00aeef;
  --event-julove-cta-banner-button-text-color: #fff;
  --event-julove-countdowntimer-container-background-color: #008ad0;
  --event-julove-countdowntimer-background-color: #f2748a;
  --event-julove-footer-social-icon-background-color: #00aeef;
  --event-julove-footer-social-icon-hover-background-color: var(--secondary-alternate-bright);
  --event-julove-threeColImage-background-color: #f2748a;
  --event-julove-threeColImage-button-background-color: #00aeef;
  --event-julove-threeColImage-button-text-color: #fff;
  --event-julove-threeColImage-text-color: #fff;
  --event-julove-carousel-slider-icon-color: #00aeef;
  --event-julove-searchbar-placeholder-text-color: #bebebe;
  --event-julove-searchbar-placeholder-mobile-text-color: #bebebe;
  --event-julove-searchbar-icon-stroke-color: #f2f2f2;
  --event-julove-searchbar-icon-mobile-stroke-color: #f2f2f2;


  // Sports
  --event-sports-deal-card-cta-btn-background-color: #0095da;
  --event-sports-cta-banner-title-text-color: var(--black);
  --event-sports-cta-banner-description-text-color: var(--black);
  --event-sports-home-header-background-color: #f5f5f5;
  --event-sports-hamburger-menu-background-colour: #ffc20e;
  --event-sports-header-colour: #ffc20e;
  --event-sports-background-colour: #008739;
  --event-sports-custom-background-colour: #008739;
  --event-sports-secondary-background-colour: #48b749;
  --event-sports-third-background-colour: #008739;
  --event-sports-text-colour: #000;
  --event-sports-secondary-text-colour: #fff;
  --event-sports-secondary-colour: #ffc20e;
  --event-sports-secondary-alternate-transparent: rgb(255 255 255 / 30%);
  --event-sports-secondary-alternate: #48b749;
  --event-sports-countdown-text-color: #fff;
  --event-sports-countdowntimer-text-color: #fff;
  --event-sports-top-banner-background-colour: #ff6900;
  --event-sports-searchbar-placeholder-text-color: #bebebe;
  --event-sports-searchbar-placeholder-mobile-text-color: #bebebe;
  --event-sports-searchbar-icon-stroke-color: #f2f2f2;
  --event-sports-searchbar-icon-mobile-stroke-color: #f2f2f2;
  --event-sports-threeColImage-text-color: #fff;
  --event-sports-second-nav-text-color: #fff;

  // Travel
  --event-travel-deal-card-cta-btn-background-image: linear-gradient(#917ecf, #aa90fe);
  --event-travel-footer-background-image: linear-gradient(#1b0827, #3c1f4d);
  --event-travel-search-bar-input-wrapper-background-color: #fff;
  --event-travel-cta-banner-title-text-color: #ece6fe;
  --event-travel-cta-banner-description-text-color: #ece6fe;
  --event-travel-cta-banner-custom-background-image: url('../../static/images/zip-travel-pattern.jpg');
  --event-travel-second-nav-background-image: linear-gradient(#917ecf, #aa90fe);
  --event-travel-nav-background-image-url: url('../../static/images/zip-travel-pattern.jpg');
  --event-travel-home-header-background-color: #f4f4f4;
  --event-travel-hamburger-menu-background-colour: #5c2d91;
  --event-travel-header-colour: #5c2d91;
  --event-travel-background-colour: #f15a22;
  --event-travel-custom-background-colour: #f15a22;
  --event-travel-secondary-background-colour: #f15a22;
  --event-travel-third-background-colour: #f37021;
  --event-travel-text-colour: #fff;
  --event-travel-secondary-text-colour: #fff;
  --event-travel-secondary-colour: #5c2d91;
  --event-travel-secondary-alternate-transparent: rgb(255 255 255 / 30%);
  --event-travel-secondary-alternate: #5c2d91;
  --event-travel-countdown-text-color: #ece6fe;
  --event-travel-countdowntimer-text-color: #ab8ffe;
  --event-travel-top-banner-background-colour: #ff6900;
  --event-travel-breadcrumb-chevron-stroke-color: #5c2d91;
  --event-travel-login-cta-background-color: #ab8ffe;
  --event-travel-login-cta-text-color: #fff;
  --event-travel-login-cta-avatar-color: #fff;
  --event-travel-signup-cta-text-color: #fff;
  --event-travel-brand-page-backdrop-color: #00aeef;
  --event-travel-brand-detail-additional-icon-color: #00aeef;
  --event-travel-second-nav-item-icon-color: #1b0826;
  --event-travel-nav-background-color: #f15a22;
  --event-travel-second-nav-background-color: #5c2d91;
  --event-travel-second-nav-text-color: #fff;
  --event-travel-logged-in-avatar-background-color: #00aeef;
  --event-travel-footer-background-color: #5c2d91;
  --event-travel-cta-banner-button-background-color: #ece6fe;
  --event-travel-cta-banner-button-text-color: #fff;
  --event-travel-countdowntimer-container-background-color: #3c1f4d;
  --event-travel-countdowntimer-background-color: #3c1f4d;
  --event-travel-footer-social-icon-background-color: #00aeef;
  --event-travel-footer-social-icon-hover-background-color: var(--secondary-alternate-bright);
  --event-travel-threeColImage-background-color: #1b0826;
  --event-travel-threeColImage-button-background-color: #00aeef;
  --event-travel-threeColImage-button-text-color: #fff;
  --event-travel-threeColImage-text-color: #fff;
  --event-travel-carousel-slider-icon-color: #00aeef;
  --event-travel-searchbar-placeholder-text-color: #bebebe;
  --event-travel-searchbar-placeholder-mobile-text-color: #bebebe;
  --event-travel-searchbar-icon-stroke-color: #f2f2f2;
  --event-travel-searchbar-icon-mobile-stroke-color: #f2f2f2;


  // Boxing Day
  --event-boxingDay-deal-card-cta-btn-background-color: #0095da;
  --event-boxingDay-cta-banner-title-text-color: var(--black);
  --event-boxingDay-cta-banner-description-text-color: var(--black);
  --event-boxingDay-home-header-background-color: #f5f5f5;
  --event-boxingDay-hamburger-menu-background-colour: #0095da;
  --event-boxingDay-header-colour: #7CC364;
  --event-boxingDay-background-colour: #7CC364;
  --event-boxingDay-custom-background-colour: #fff;
  --event-boxingDay-secondary-background-colour: #904b9D;
  --event-boxingDay-third-background-colour: #7CC364;
  --event-boxingDay-text-colour: #fff;
  --event-boxingDay-secondary-text-colour: #fff;
  --event-boxingDay-secondary-colour: #904B9D;
  --event-boxingDay-secondary-alternate-transparent: hsla(0deg 0% 100% / 30%);
  --event-boxingDay-secondary-alternate: #904B9D;
  --event-boxingDay-countdown-text-color: #fff;
  --event-boxingDay-countdowntimer-text-color: #fff;
  --event-boxingDay-top-banner-background-colour: #ff6900;
  --event-boxingDay-breadcrumb-chevron-stroke-color: #828282;
  --event-boxingDay-login-cta-background-color: #E3D350;
  --event-boxingDay-login-cta-text-color: #fff;
  --event-boxingDay-login-cta-avatar-color: #fff;
  --event-boxingDay-signup-cta-text-color: #fff;
  --event-boxingDay-brand-page-backdrop-color: #E3D350;
  --event-boxingDay-brand-detail-additional-icon-color: #5E6347;
  --event-boxingDay-second-nav-item-icon-color: #E3D350;
  --event-boxingDay-nav-background-color: #A7C14D;
  --event-boxingDay-second-nav-background-color: #5E6347;
  --event-boxingDay-second-nav-text-color: #fff;
  --event-boxingDay-logged-in-avatar-background-color: #e0a3df;
  --event-boxingDay-footer-background-color: #A7C14D;
  --event-boxingDay-cta-banner-button-background-color: #E3D350;
  --event-boxingDay-cta-banner-button-text-color: #fff;
  --event-boxingDay-countdowntimer-container-background-color: #E3D350;
  --event-boxingDay-countdowntimer-background-color: #A7C14D;
  --event-boxingDay-footer-social-icon-background-color: #E3D350;
  --event-boxingDay-footer-social-icon-hover-background-color: var(--secondary-alternate-bright);
  --event-boxingDay-threeColImage-background-color: #A7C14D;
  --event-boxingDay-threeColImage-button-background-color: #904B9D;
  --event-boxingDay-threeColImage-button-text-color: #fff;
  --event-boxingDay-threeColImage-text-color: #fff;
  --event-boxingDay-carousel-slider-icon-color: #904B9D;
  --event-boxingDay-searchbar-placeholder-text-color: #fff;
  --event-boxingDay-searchbar-placeholder-mobile-text-color: #fff;
  --event-boxingDay-searchbar-icon-stroke-color: #f2f2f2;
  --event-boxingDay-searchbar-icon-mobile-stroke-color: #f2f2f2;

  // Fashion Frenzy
  --event-fashion-deal-card-cta-btn-background-color: #0095da;
  --event-fashion-cta-banner-title-text-color: var(--black);
  --event-fashion-cta-banner-description-text-color: var(--black);
  --event-fashion-home-header-background-color: #f5f5f5;
  --event-fashion-hamburger-menu-background-colour: #0095da;
  --event-fashion-header-colour: #5c2d91;
  --event-fashion-background-colour: #5c2d91;
  --event-fashion-custom-background-colour: #fff;
  --event-fashion-secondary-background-colour: #bd1a8d;
  --event-fashion-third-background-colour: #5c2d91;
  --event-fashion-text-colour: #fff;
  --event-fashion-secondary-text-colour: #fff;
  --event-fashion-secondary-colour: #bd1a8d;
  /* stylelint-disable-next-line */
  --event-fashion-secondary-alternate-transparent: hsla(0, 0%, 100%, 0.3);
  --event-fashion-secondary-alternate: #bd1a8d;
  --event-fashion-countdown-text-color: #003087;
  --event-fashion-countdowntimer-text-color: #fff;
  --event-fashion-top-banner-background-colour: #ff6900;
  --event-fashion-breadcrumb-chevron-stroke-color: #828282;
  --event-fashion-login-cta-background-color: #0070e0;
  --event-fashion-login-cta-text-color: #fff;
  --event-fashion-login-cta-avatar-color: #fff;
  --event-fashion-signup-cta-text-color: #001435;
  --event-fashion-brand-page-backdrop-color: #0070e0;
  --event-fashion-brand-detail-additional-icon-color: #0070e0;
  --event-fashion-second-nav-item-icon-color: #0070e0;
  --event-fashion-nav-background-color: #f3f3f6;
  --event-fashion-second-nav-background-color: #001c74;
  --event-fashion-second-nav-text-color: #fff;
  --event-fashion-logged-in-avatar-background-color: #e0a3df;
  --event-fashion-footer-background-color: #2e2e2e;
  --event-fashion-cta-banner-button-background-color: #bd1a8d;
  --event-fashion-cta-banner-button-text-color: #fff;
  --event-fashion-countdowntimer-container-background-color: #fff;
  --event-fashion-countdowntimer-background-color: #0070e0;
  --event-fashion-footer-social-icon-background-color: #0070e0;
  --event-fashion-footer-social-icon-hover-background-color: var(--secondary-alternate-bright);
  --event-fashion-threeColImage-background-color: #0095da;
  --event-fashion-threeColImage-button-background-color: #bd1a8d;
  --event-fashion-threeColImage-button-text-color: #fff;
  --event-fashion-threeColImage-text-color: #fff;
  --event-fashion-carousel-slider-icon-color: #bd1a8d;
  --event-fashion-searchbar-placeholder-text-color: #001435;
  --event-fashion-searchbar-placeholder-mobile-text-color: #fff;
  --event-fashion-searchbar-icon-stroke-color: #001435;
  --event-fashion-searchbar-icon-mobile-stroke-color: #fff;

  // PayPal
  --event-paypal-deal-card-cta-btn-background-color: #60cdff;
  --event-paypal-cta-banner-title-text-color: var(--black);
  --event-paypal-cta-banner-description-text-color: var(--black);
  --event-paypal-home-header-background-color: #f5f5f5;
  --event-paypal-hamburger-menu-background-colour: #60cdff;
  --event-paypal-header-colour: #60cdff;
  --event-paypal-background-colour: #60cdff;
  --event-paypal-custom-background-colour: #60cdff;
  --event-paypal-secondary-background-colour: #60cdff;
  --event-paypal-third-background-colour: #60cdff;
  --event-paypal-text-colour: #000;
  --event-paypal-second-nav-background-color: #60cdff;
  --event-paypal-second-nav-text-color: #000;
  --event-paypal-footer-background-color: #000;
  --event-paypal-login-cta-background-color: #000;
  --event-paypal-login-cta-text-color: #fff;
  --event-paypal-login-cta-avatar-color: #fff;
  --event-paypal-nav-background-color: #fff;
  --event-paypal-searchbar-placeholder-text-color: #000;
  --event-paypal-searchbar-placeholder-mobile-text-color: #000;
  --event-paypal-searchbar-icon-stroke-color: #000;
  --event-paypal-searchbar-icon-mobile-stroke-color: #000;
  --event-paypal-second-nav-item-icon-color: #000;
  --event-paypal-countdown-text-color: #60cdff;
  --event-paypal-countdowntimer-text-color: #fff;
  --event-paypal-countdowntimer-container-background-color: #fff;
  --event-paypal-countdowntimer-background-color: #000;
  --event-paypal-carousel-slider-icon-color: #60cdff;
  --event-paypal-cta-banner-button-background-color: #fff;
  --event-paypal-cta-banner-button-text-color: #000;
  --event-paypal-threeColImage-background-color: #60cdff;
  --event-paypal-threeColImage-button-background-color: #fff;
  --event-paypal-threeColImage-button-text-color: #000;
  --event-paypal-threeColImage-text-color: #000;
  --event-paypal-footer-social-icon-background-color: #60cdff;
  --event-paypal-footer-social-icon-hover-background-color: #fff;
  --event-paypal-footer-social-icon-color: #fff;
  --event-paypal-footer-social-icon-hover-color: #60cdff;
  --event-paypal-brand-page-backdrop-color: #000;
  --event-paypal-brand-detail-additional-icon-color: #60cdff;


  // Glow
  --event-glow-deal-card-cta-btn-background-color: #9e5e2e;
  --event-glow-cta-banner-title-text-color: var(--black);
  --event-glow-cta-banner-description-text-color: var(--black);
  --event-glow-home-header-background-color: #f5f5f5;
  --event-glow-hamburger-menu-background-colour: #9e5e2e;
  --event-glow-header-colour: #9e5e2e;
  --event-glow-background-colour: #9e5e2e;
  --event-glow-custom-background-colour: #d68c6b;
  --event-glow-secondary-background-colour: #9e5e2e;
  --event-glow-second-nav-text-color: #fff;
  --event-glow-third-background-colour: #9e5e2e;
  --event-glow-text-colour: #fff;
  --event-glow-second-nav-background-color: #9e5e2e;
  --event-glow-footer-background-color: #9e5e2e;
  --event-glow-login-cta-background-color: #9e5e2e;
  --event-glow-login-cta-text-color: #fff;
  --event-glow-login-cta-avatar-color: #fff;
  --event-glow-nav-background-color: #d68c6b;
  --event-glow-searchbar-placeholder-text-color: #000;
  --event-glow-searchbar-placeholder-mobile-text-color: #000;
  --event-glow-searchbar-icon-stroke-color: #000;
  --event-glow-searchbar-icon-mobile-stroke-color: #000;
  --event-glow-second-nav-item-icon-color: #efb0ab;
  --event-glow-countdown-text-color: #fff;
  --event-glow-countdowntimer-text-color: #fff;
  --event-glow-countdowntimer-container-background-color: #d68c6b;
  --event-glow-countdowntimer-background-color: #efb0ab;
  --event-glow-carousel-slider-icon-color: #9e5e2e;
  --event-glow-cta-banner-button-background-color: #9e5e2e;
  --event-glow-cta-banner-button-text-color: #fff;
  --event-glow-threeColImage-background-color: #d68c6b;
  --event-glow-threeColImage-button-background-color: #9e5e2e;
  --event-glow-threeColImage-button-text-color: #000;
  --event-glow-threeColImage-text-color: #000;
  --event-glow-footer-social-icon-background-color: #efb0ab;
  --event-glow-footer-social-icon-hover-background-color: #d68c6b;
  --event-glow-footer-social-icon-color: #fff;
  --event-glow-footer-social-icon-hover-color: #efb0ab;
  --event-glow-brand-page-backdrop-color: #efb0ab;
  --event-glow-brand-detail-additional-icon-color: #9e5e2e;

  // Melbourne Airport
  --event-melbAirport-deal-card-cta-btn-background-color: #151818;
  --event-melbAirport-cta-banner-title-text-color: var(--black);
  --event-melbAirport-cta-banner-description-text-color: var(--black);
  --event-melbAirport-home-header-background-color: #f5f5f5;
  --event-melbAirport-hamburger-menu-background-colour: #151818;
  --event-melbAirport-header-colour: #151818;
  --event-melbAirport-background-colour: #151818;
  --event-melbAirport-custom-background-colour: #d8c294;
  --event-melbAirport-secondary-background-colour: #151818;
  --event-melbAirport-third-background-colour: #151818;
  --event-melbAirport-text-colour: #fff;
  --event-melbAirport-second-nav-background-color: #151818;
  --event-melbAirport-second-nav-text-color: #fff;
  --event-melbAirport-footer-background-color: #151818;
  --event-melbAirport-login-cta-background-color: #151818;
  --event-melbAirport-login-cta-text-color: #fff;
  --event-melbAirport-login-cta-avatar-color: #fff;
  --event-melbAirport-nav-background-color: #f3f3f6;
  --event-melbAirport-searchbar-placeholder-text-color: #000;
  --event-melbAirport-searchbar-placeholder-mobile-text-color: #000;
  --event-melbAirport-searchbar-icon-stroke-color: #000;
  --event-melbAirport-searchbar-icon-mobile-stroke-color: #000;
  --event-melbAirport-second-nav-item-icon-color: #d8c294;
  --event-melbAirport-countdown-text-color: #000;
  --event-melbAirport-countdowntimer-text-color: #fff;
  --event-melbAirport-countdowntimer-container-background-color: #f3f3f6;
  --event-melbAirport-countdowntimer-background-color: #d8c294;
  --event-melbAirport-carousel-slider-icon-color: #151818;
  --event-melbAirport-cta-banner-button-background-color: #151818;
  --event-melbAirport-cta-banner-button-text-color: #fff;
  --event-melbAirport-threeColImage-background-color: #d8c294;
  --event-melbAirport-threeColImage-button-background-color: #151818;
  --event-melbAirport-threeColImage-button-text-color: #fff;
  --event-melbAirport-threeColImage-text-color: #000;
  --event-melbAirport-footer-social-icon-background-color: #d8c294;
  --event-melbAirport-footer-social-icon-hover-background-color: #f3f3f6;
  --event-melbAirport-footer-social-icon-color: #000;
  --event-melbAirport-footer-social-icon-hover-color: #d8c294;
  --event-melbAirport-brand-page-backdrop-color: #d8c294;
  --event-melbAirport-brand-detail-additional-icon-color: #151818;


  // Tech
  --event-tech-deal-card-cta-btn-background-color: #00bef2;
  --event-tech-cta-banner-title-text-color: var(--black);
  --event-tech-cta-banner-description-text-color: var(--black);
  --event-tech-home-header-background-color: #f5f5f5;
  --event-tech-hamburger-menu-background-colour: #00bef2;
  --event-tech-header-colour: #00bef2;
  --event-tech-background-colour: #00bef2;
  --event-tech-custom-background-colour: #b9e5fa;
  --event-tech-secondary-background-colour: #00bef2;
  --event-tech-third-background-colour: #00bef2;
  --event-tech-text-colour: #fff;
  --event-tech-second-nav-background-color: #454547;
  --event-tech-second-nav-text-color: #fff;
  --event-tech-footer-background-color: #454547;
  --event-tech-login-cta-background-color: #454547;
  --event-tech-login-cta-text-color: #fff;
  --event-tech-login-cta-avatar-color: #fff;
  --event-tech-nav-background-color: #00bef2;
  --event-tech-searchbar-placeholder-text-color: #000;
  --event-tech-searchbar-placeholder-mobile-text-color: #000;
  --event-tech-searchbar-icon-stroke-color: #000;
  --event-tech-searchbar-icon-mobile-stroke-color: #000;
  --event-tech-second-nav-item-icon-color: #b9e5fa;
  --event-tech-countdown-text-color: #000;
  --event-tech-countdowntimer-text-color: #000;
  --event-tech-countdowntimer-container-background-color: #00bef2;
  --event-tech-countdowntimer-background-color: #b9e5fa;
  --event-tech-carousel-slider-icon-color: #454547;
  --event-tech-cta-banner-button-background-color: #454547;
  --event-tech-cta-banner-button-text-color: #fff;
  --event-tech-threeColImage-background-color: #b9e5fa;
  --event-tech-threeColImage-button-background-color: #454547;
  --event-tech-threeColImage-button-text-color: #fff;
  --event-tech-threeColImage-text-color: #000;
  --event-tech-footer-social-icon-background-color: #b9e5fa;
  --event-tech-footer-social-icon-hover-background-color: #00bef2;
  --event-tech-footer-social-icon-color: #454547;
  --event-tech-footer-social-icon-hover-color: #b9e5fa;
  --event-tech-brand-page-backdrop-color: #b9e5fa;
  --event-tech-brand-detail-additional-icon-color: #00bef2;
}