/** FEATURE ON - LOGOS **/
@import '../../styles/import';

.brandwall-blk {
  padding: 55px 0;

  @include media-breakpoint-down('xs') {
    padding: 63px 0 80px;
  }

  .ttl {
    /* stylelint-disable */
    color: rgba(0, 0, 0, 85%);
    /* stylelint-enable */
    text-align: center;
    margin-top: 0;
    margin-bottom: 44px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    @include media-breakpoint-down('xs') {
      margin-bottom: 24px;
    }
  }

  // .logos-list {
  //     gap: 10px;

  //     .logo-item {
  //         display: flex;
  //         width: calc((1100px - 50px) / 6);
  //         border-radius: 16px;
  //         overflow: hidden;

  //         img {
  //             width: 100%;
  //             height: auto;
  //         }
  //     }

  //     @include media-breakpoint-down('md') {
  //         width: calc(170px * 3 + 20px);
  //         margin: 0 auto;
  //         flex-wrap: wrap;

  //         .logo-item {
  //             width: 170px;
  //             height: 96px;
  //             align-items: center;
  //             background-color: white;
  //             border-radius: 16px;
  //             overflow: hidden;
  //         }
  //     }

  //     @include media-breakpoint-down('xs') {
  //         width: calc(170px * 2 + 10px);
  //     }
  // }
}

.brandwall-brands {
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
  }

  $self: &;

  & {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  @include media-breakpoint-up('lg') {
    margin-top: -spacing('xl');
  }

  &--presentation-default {
    flex-wrap: wrap;
    justify-content: center;

    @include desktop() {
      justify-content: center;
    }
  }

  &__non-event {
    display: flex;
  }

  // Add a spare last item to handle the case of two items in the last row
  // with using space-between above, the two items will show on the outside
  // edges of the row, looking silly.
  &::after {
    content: '';
    width: 30%;

    @include desktop() {
      display: none;
    }
  }

  &__brand {
    margin-top: spacing('default');
    position: relative;
    width: 134px;
    height: 79px;
    background-color: var(--white);

    // border: 1px solid var(--silver);
    border-color: transparent;
    border-radius: 16px;
    margin-right: auto;

    @include desktop() {
      width: 151.28px;
      height: 79px;
      margin-right: spacing('lg');
      margin-top: spacing('lg');
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    // Removed Brand Wall Greyscale Effect
    // filter: grayscale(100%);
    // opacity: .3;
    cursor: pointer;

    .no-touchevents &:hover {
      filter: none;
      opacity: 1;
    }
  }

  &__logo {
    max-width: 135px;
    max-height: 66px;
  }
}
