@import '../../styles/import';

/* stylelint-disable-next-line scss/dollar-variable-pattern */
$summaryMinHeight: 100px;

.brand-detail-additional-summary {
  @include desktop() {
    min-height: $summaryMinHeight;
  }

  &__title {
    @include font('barlow', 'xl', 'lg', 'heavy');

    margin-top: 0;
  }

  &__summary {
    @include font('barlow', 'md', 'lg', 'regular');

    margin-top: spacing('md');
    height: line-height('lg') * 2;
    position: relative;
    overflow: hidden;
    text-align: left;
  }

  &__link-wrapper {
    float: right;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--white);
  }

  &__link {
    @include font('barlow', 'md', 'lg', 'medium');

    text-decoration: none;
    color: var(--primary);
    border: none;
    padding: 0;
    background-color: var(--white);

    &:focus {
      border: none;
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__modal-logo-and-name {
    margin-bottom: spacing('3xl');
    margin-top: spacing('default');

    @include desktop() {
      margin-top: 0;
    }
  }

  &__modal-title {
    @include font('barlow', 'xl', $weight: 'heavy');

    margin-top: 0;
    margin-bottom: spacing('lg');

    @include desktop() {
      @include font('barlow', 'md', $weight: 'heavy');
    }
  }
}