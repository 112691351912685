/** Coming Event CSS **/
@import '../../../../../../styles/import';

.cf-upcoming {
    gap: 54px;

    @include media-breakpoint-down('lg') {
        gap: 48px;
    }
    
    .head-blk {
        text-align: center;
        gap: 10px;
    }

    .ttl-blk {
        font-size: 28px;  
        line-height: 150%;
    }

    .sub-heading {
        color: var(--cf-grey3);
        font-size: 16px;
        line-height: 150%;
        max-width: 768px;
    }

    .three-blk {
        gap: 18px;

        @include media-breakpoint-down('lg') {
            flex-direction: column;
        }
    }
}

