@import '../../styles/import';

.grid {
    margin: 0 auto;

    @include media-breakpoint-down('xxs') {
        width: 100%;
    }
}

.container-new {
    width: 1320px !important;

    @media screen and (max-width: 1320px) {
        width: 1227px !important;
    }

    @media screen and (max-width: 1227px) {
        width: 914px !important;
    }

    @media screen and (max-width: 960px) {
        width: 688px !important;
    }

    @media screen and (max-width: 768px) {
        width: 102vw !important;
    }
}
