@import '../../../styles/import';

.search-refine-by {
    $self: &;

    display: flex;
    height: 100%;

    &--context-modal {
        padding: 0 $flexboxgrid-gutter-width $flexboxgrid-gutter-width;
    }
}
