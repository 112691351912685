@import '../../styles/import';

.image-and-title {
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop() {
        justify-content: flex-start;
    }

    &__image-wrapper {
        display: none;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        position: relative;
        border: 1px solid var(--light-grey);
        margin: 0;
        flex-grow: 0;
        flex-shrink: 0;

        @include desktop() {
            display: block;
        }
    }

    &__image {
        display: block;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__title {
        @include font('barlow', 'xxl', $line-height: 'lg', $weight: 'heavy');

        text-align: center;

        @include desktop() {
            @include font('barlow', 'xl', $line-height: 'lg', $weight: 'heavy');

            margin: 0 0 0 spacing('md');
        }
    }

    &--pad-title-on-mobile & {
        &__title {
            @include mobile() {
                padding-left: 3.5rem;
                padding-right: 3.5rem;
            }
        }
    }
}