/** FEATURE ON - LOGOS **/
@import '../../../../../styles/import';

.cf-feature-blk {
    padding: 110px 0;

    @include media-breakpoint-down('xs') {
        padding: 63px 0 80px;
    }

    .ttl {
        color: white;
        text-align: center;
        margin-top: 0;
        margin-bottom: 44px;

        @include media-breakpoint-down('xs') {
            margin-bottom: 24px;
        }
    }

    .logos-list {
        gap: 10px;

        .logo-item {
            display: flex;
            width: calc((1100px - 50px) / 6);
            border-radius: 16px;
            overflow: hidden;
            
            img {
                width: 100%;
                height: auto;
            }
        }

        @include media-breakpoint-down('md') {
            width: calc(170px * 3 + 20px);
            margin: 0 auto;
            flex-wrap: wrap;

            .logo-item {
                width: 170px;
                height: 96px;
                align-items: center;
                background-color: white;
                border-radius: 16px;
                overflow: hidden;
            }
        }

        @include media-breakpoint-down('xs') {
            width: calc(170px * 2 + 10px);
        }
    }
}