@import '../../styles/import';

.gradient-container {
  overflow: hidden;
  position: relative;

  &__content {
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow-y: auto;
    box-sizing: border-box;
  }

  &__gradient {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 80px;
    left: 0;
    opacity: 0;
    pointer-events: none;

    &--top {
      background: linear-gradient(to bottom, var(--white), rgb(255 255 255 / 0%));
      top: 0;
    }

    &--bottom {
      background: linear-gradient(to top, var(--white), rgb(255 255 255 / 0%));
      bottom: 0;
    }
  }
}
