/** EVENT CARD CSS */
@import '../../../../../../styles/import';

.event-card {
    background-color: var(--cf-light-purple);
    border-radius: 16px;
    flex: 1;

    .inner {
        padding: 34px;
    }

    &.cf-gray-card {
        .inner {
            gap: 32px;
        }

        .ttl {
            color: var(--cf-primary);
            margin: 0;
            padding-top: 14px;
        }

        .desc {
            color: var(--cf-grey3);
            font-size: 14px;
            font-weight: 400;
        }

        .cta-blk {
            .button {
                min-width: 160px;
            }
        }

        @include media-breakpoint-down('lg') {
            .inner {
                text-align: center;
            }

            .ttl {
                padding-top: 0;
            }

            .cta-blk {
                .button {
                    margin: 0 auto;
                }
            }
        }
    }

    &.cf-second-card {
        background-color: var(--cf-primary);
        color: white;

        .meta-date {
            font-size: 14px;
        }

        .inner {
            padding: 26px;
            gap: 30px;

            .head {
                text-align: center;
            }
        }

        .img {
            width: 160px;
            height: 102px;
            margin: 0 auto;
            margin-bottom: 20px;
            margin-top: 8px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .ttl {
            color: white;
            font-size: 18px;
            line-height: 150%;
        }
    }

    &.cf-travel-card {
        background-color: var(--cf-orange);
    }

    &.cf-mayhem-card {
        background-color: var(--cf-blue);
    }

    .count-down-blk {
        gap: 6px;
    }

    .c-cell {
        width: 98px;
        background-color: rgb(255 255 255 / 30%);
        border-radius: 12px;
        padding: 20px 16px 10px;

        .c-number {
            @include font('barlow', 'lg', 'sm', 'regular');

            line-height: 1;
            font-size: 34px;
            margin-bottom: 24px;
        }

        .c-txt {
            @include font('barlow', 'lg', 'sm', 'regular');

            line-height: 1;
            font-size: 14px;
        }
    }

    @include media-breakpoint-down('xs') {
        &.cf-second-card {
            .img {
                margin-top: 0;
            }

            .inner {
                padding: 24px;
            }
        }

        .c-cell {
            width: 80px;
            height: 93px;
            padding: 16px 0 0;

            .c-number {
                font-size: 32px;
                margin-bottom: 24px;
            }
        }
    }
}

.date-btn-wrap {
    svg {
        margin-top: -2px;
    }
}