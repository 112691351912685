@import '../../../styles/import';

.second-nav {
    &__item-icon {
        @media screen and (max-width: 992px) {
            width: 19px;
            height: 19px;
        }
    }

    &__item-fill {
        @each $theme in $themes {
            &--#{$theme} path {
                fill: var(--event-#{$theme}-second-nav-item-icon-color);
            }
        }
    }
}
