/* stylelint-disable selector-class-pattern */
@import '../../styles/import';

.account-form-signup {
  $self: &;

  &__page-heading {
    @include font('barlow', 'xxl', 'xl', 'medium');

    margin-top: spacing('6xl');
    text-align: center;

    @include desktop() {
      margin-top: spacing('7xl');
    }
  }

  &__page-intro {
    @include font('barlow', 'md', 'lg');

    margin-top: spacing('2xl');
    text-align: center;
  }

  &__field-container {
    /* stylelint-disable-line */
    input,
    select {
      border-color: var(--black);
    }

    .select--error {
      border-color: var(--error);
    }

    .checkbox .checkbox__icon rect {
      stroke: var(--black);
    }

    .checkbox--checked .checkbox__icon rect {
      stroke: var(--primary);
    }

    .checkbox--error .checkbox__icon rect {
      stroke: var(--error);
    }

    @media screen and (min-width: 767px) {
      border-right: 1.5px solid var(--clear-grey);
    }
  }

  &__first-form-field {
    margin-top: spacing('5xl');
  }

  &__first-checkbox {
    margin-top: spacing('xl');

    @include desktop() {
      margin-top: spacing('lg');
    }
  }

  &__submit {
    margin-top: spacing('4xl');
    width: 100%;

    @include desktop() {
      margin-top: spacing('6xl');
    }

    #{$self}--has-error & {
      margin-top: spacing('2xl');

      @include desktop() {
        margin-top: spacing('3xl');
      }
    }
  }

  a {
    @include underline-on-hover();

    & {
      color: var(--primary);
      cursor: pointer;
    }
  }

  &__footer {
    @include font('barlow', 'md', 'lg');

    margin-top: spacing('2xl') !important;
    text-align: center;
    border-bottom: none;
    padding-bottom: 0;

    @media screen and (max-width: 767px) {
      border-bottom: 1.5px solid var(--clear-grey);
      padding-bottom: spacing('2xl');
    }
  }

  &__error-message {
    @include font('barlow', 'md', 'lg');

    color: var(--error);
    margin-top: spacing('4xl');

    @include desktop() {
      text-align: center;
      margin-top: spacing('6xl');
    }
  }
}
