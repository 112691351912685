@import '../../styles/import';

$breakpoint-small-phones: xxxs;
$breakpoint-big-phones: xs;
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$dealCardMaxWidth: 300px;

// prepending with div to override flexboxgrid row class
div.deal-slider {
  margin-top: spacing('default');

  @include media-breakpoint-down($breakpoint-big-phones) {
    margin-top: spacing('default');
    margin-left: 0;
    margin-right: 0;
    padding-top: spacing('default');
    padding-bottom: 0;
    box-sizing: border-box;
    display: inline-flex;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;

    .touchevents & {
      scrollbar-width: none;

      /* Firefox */
      -ms-overflow-style: none;

      /* IE 10+ */
      &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
      }
    }
  }

  .no-touchevents & {
    overflow-y: hidden;

    @include desktop() {
      overflow: visible;
    }
  }

  &__slide {
    @include media-breakpoint-down($breakpoint-big-phones) {
      margin-left: spacing('xl');
      margin-bottom: 0;
      box-sizing: content-box;
      max-width: $dealCardMaxWidth;
      width: 69vw;
      flex-grow: 0;
      flex-shrink: 0;

      &:last-child {
        border-right: solid spacing('xl') transparent;
      }

      .no-touchevents & {
        margin-bottom: spacing('default');
      }
    }

    @include media-breakpoint-down($breakpoint-small-phones) {
      margin-left: spacing('lg');
      width: 75vw;

      &:last-child {
        border-right: solid spacing('lg') transparent;
      }
    }

    @include desktop() {
      margin-top: spacing('xl');
    }
  }
}

.deal-collection-title {
  margin-top: spacing('6xl');

  @include media-breakpoint-down($breakpoint-big-phones) {
    margin-top: spacing('3xl');
  }

  &__link {
    text-decoration: none;
    color: var(--primary);
  }

  &__title {
    text-decoration: none;
    margin-top: 0;

    @include font(barlow, 'xl', 'lg', 'heavy');
  }
}