@import '../../styles/import';

.overlay {
    @include z-index('above-page-content');

    position: fixed;
    inset: 0;
    background: var(--overlay);

    &--enter {
        opacity: 0;
    }

    &--enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }

    &--exit {
        opacity: 1;
    }

    &--exit-active {
        opacity: 0;
        transition: opacity 300ms;
    }
}
