@import '../../styles/import';

.category-list {
    $self: &;

    &__cell {
        @include desktop() {
            margin: auto;
        }
    }

    @include media-breakpoint-down('xs') {
        &__row {
            margin: 0;
        }

        &__cell {
            padding: 0;
            width: 100%;
        }
    }

    &__toggle {
        @include font('barlow', 'xl', 'lg', 'heavy');

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: spacing('lg') spacing('xl');
        border-bottom: solid 1px var(--light-grey);
        border-style: none none solid;
        background: transparent;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        @include desktop() {
            display: none;
        }
    }

    &__toggle-text {
        margin-right: spacing('default');
    }

    &__toggle-chevron {
        line-height: 0;
        font-size: 0;
        transform: rotate(270deg);
        transition: transform 300ms ease-in-out;

        #{$self}--shown & {
            transform: rotate(90deg);
        }
    }

    &__categories {
        height: 0;
        overflow: hidden;
        list-style: none;
        max-width: fit-content;
        margin: spacing('md') 0;
        padding: 0;

        #{$self}--shown & {
            height: auto;
        }

        @include desktop() {
            height: auto;
            display: flex;
            margin: 0;
            flex-flow: row wrap;
            justify-content: center;
            margin-top: spacing('xl');
            overflow: visible;
            max-width: none;
        }
    }
}