@import '../../styles/import';

.grid {
    margin: 0 auto;

    @include media-breakpoint-down('xxs') {
        width: 100%;
    }
}

