/** Main Event CSS **/
@import '../../../../../../styles/import';

.count-down-blk {
    gap: 12px;

    @include media-breakpoint-down('xs') {
        gap: 8px;
    }
}

.cf-main-event {
    background-color: var(--cf-primary);
    color: white;
    border-radius: 16px;
    padding: 56px 111px;
    filter: drop-shadow(0 0 52px rgb(141  108 178 / 70%));

    .img {
        width: 277px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .ct {
        text-align: center;
        line-height: 150%;
        color: white;

        .ttl {
            font-size: 24px;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: auto;
            color: white;
        }

        .meta-date {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .count-down-blk {
        padding-top: 32px;
    }

    .button, a.button  {
        &--type-cfwhiteoutline {
            border-color: var(--cf-yellow);
            color: var(--cf-yellow);

            &:hover {
                background-color: rgb(247 243 16 / 30%);
            }

            > svg {
                stroke: var(--cf-yellow);
                margin-bottom: 1px;
            }
        }
    }

    .cta-blk {
        padding-top: 32px;
        position: relative;

        .pos-arrow {
            position: absolute;
            top: -28px;
            right: 12px;
        }

        .pos-text {
            position: absolute;
            top: -60px;
            right: -90px;

            @include media-breakpoint-down('xlg') {
                right: -58px;
            }

            @include media-breakpoint-down('lg') {
                right: -90px;
            }
        }

        .guide-box-sm {
            display: none;
        }
    }

    @include media-breakpoint-down('xlg') {
        padding: 56px 70px;
        filter: none;
    }

    @include media-breakpoint-down('md') {
        >.inner {
            gap: 32px;

            &.cf-flex {
                &.flex-row {
                    flex-direction: column;
                }
            }
        }
    }

    @include media-breakpoint-down('xs') {
        padding: 40px 0 80px;

        .img {
            width: 204px;
        }

        .count-down-blk {
            justify-content: center;
        }

        .cta-blk {
            .pos-arrow {
                display: none;
            }
    
            .pos-text {
                display: none;
            }

            .guide-box-sm {
                display: block;
                position: relative;
                width: 178px;
                height: 126px;
                margin: 0 auto;
                margin-top: 16px;

                .pos-arrow-sm {
                    position: absolute;
                    left: 56px;
                    top: 0;
                }

                .pos-text-sm {
                    position: absolute;
                    right: 0;
                    bottom: 5px;
                }
            }
        }
    }
}