@import '../../styles/import';

.brand-detail-additional {
    @include desktop() {
        margin-top: 3em;
    }

    &__additional-grid.container {
        width: 100%;

        .brand-detail-additional-summary {
            min-height: auto;
            padding: 1em;
            border: 1px solid var(--light-grey);
            cursor: pointer;
            position: relative;

            h2 {
                font-size: 1em;

                // &::after {
                //     content: url('../../static/icons/arrow-right.svg');
                //     position: absolute;
                //     right: 1em;
                // }
            }

            @each $theme in $themes {
                .brand-detail-additional-summary__#{$theme} {
                    &::after {
                        content: url('../../static/icons/arrow-right-#{$theme}.svg');
                        position: absolute;
                        right: 1em;
                    }
                }
            }
        }
    }

    &__payment {
        margin: 0;
    }

    &__refundreturnpolicy {
        @include desktop() {
            margin-top: 0;
        }
    }
}
