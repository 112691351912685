/** FOOTER CSS */
@import '../../../styles/import';

.cf-footer-blk {
    color: white;
    border-top: 1px solid var(--cf-gray4, #A0A4B8);
    padding: 60px 0;
}

.social-links {
    gap: 32px;

    a {
        display: block;
        color: white;
        transition: all 0.25s ease-out;

        >span {
            margin-right: 5px;
        }

        &:hover {
            color: rgb(255 255 255 / 70%);
        }
    }

    @include media-breakpoint-down('xs') {
        gap: 34px;
        font-size: 19px;
    }
}

.footer-menu {
    font-size: 15px;
    line-height: 130%;
    margin-bottom: 146px;

    .ttl {
        color: white;
    }

    .ft-menu {
        max-width: 332px;
        gap: 24px 74px;

        >a {
            display: block;
            color: white;
            transition: all 0.25s ease-out;
            min-width: 118px;
            line-height: 1.4;

            &:hover {
                color: rgb(255 255 255 / 70%);
            }
        }
    }

    @include media-breakpoint-down('md') {
        .ft-menu {
            gap: 24px;
        }
    }

    @include media-breakpoint-down('sm') {
        &.cf-flex {
            &.flex-row {
                flex-direction: column;
                align-items: start;
                gap: 34px;
                margin-bottom: 60px;
            }
        }

        .ft-menu {
            gap: 48px;
        }
    }

    @include media-breakpoint-down('xs') {
        &.cf-flex {
            &.flex-row {
                gap: 60px;
                margin-bottom: 60px;
            }
        }

        .ttl {
            &.head-lg {
                font-size: 32px;
            }
        }

        .ft-menu {
            font-size: 19px;

            &.flex-row {
                flex-direction: column;
                gap: 34px;
            }
        }
    }
}
