/* stylelint-disable scss/no-global-function-names */
/* stylelint-disable scss/at-if-no-null */
@function font-size($size) {
  $fetched-value: map-get($font-sizes, $size);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Font size `#{$size}` not found. Available sizes: #{available-names($font-sizes)}';
  }
}

@function line-height($height) {
  $fetched-value: map-get($line-heights, $height);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Line height `#{$height}` not found. Available heights: #{available-names($line-heights)}';
  }
}

@function font-weight($family, $weight) {
  $weights: map-get($font-weights, $family);

  @if $weights == null {
    @error 'Font family `#{$family}` not found. Available families: #{available-names($font-weights)}';
  }

  $fetched-value: map-get($weights, $weight);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Font weight `#{$weight}` not found for #{$family}. Available weights: #{available-names($weights)}';
  }
}

@function font($family, $size, $line-height: 'md', $weight: 'regular', $style: 'normal') {
  $font-family: font-family($family);
  $font-size: font-size($size);
  $font-line-height: line-height($line-height);
  $font-weight: font-weight($family, $weight);

  @return #{$style $font-weight $font-size#{'/'}$font-line-height $font-family};
}

@mixin font($family, $size, $line-height: 'md', $weight: 'regular', $style: 'normal') {
  font: font($family, $size, $line-height, $weight, $style);
}

@mixin underline-on-hover($underline-color: currentColor, $pseudo-element: 'after', $display: 'inline-block') {
  display: unquote($display);
  text-decoration: none;
  position: relative;

  :global(.no-touchevents) &:#{$pseudo-element} {
    position: absolute;
    top: 100%;
    width: 100%;
    display: block;
    content: '';
    height: 2px;
    background: $underline-color;
    opacity: 0;
    visibility: hidden;
    transform: translateY(2px);
    transition: opacity 300ms, transform 300ms, visibility 0s 300ms;
  }

  :global(.no-touchevents) &:hover:#{$pseudo-element} {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition: opacity 300ms, transform 300ms, visibility 0s;
  }
}

@mixin placeholder {
  /* stylelint-disable-next-line selector-pseudo-element-no-unknown */
  &::input-placeholder {
    @content;
  }

  &:placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  &:input-placeholder {
    @content;
  }
}
