.clear-input {
    appearance: none;

    &[type='text']::-ms-clear,
    &[type='search']::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    &[type='text']::-ms-reveal,
    &[type='search']::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
        display: none;
    }
}
