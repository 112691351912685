@import '../../../styles/import';

.search-suggestion {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
        @include font('barlow', 'md', 'xl', 'medium');

        color: var(--secondary-alternate);
        text-decoration: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__action {
        background: none;
        border: 0;
        cursor: pointer;
        outline: 0;
        line-height: 1;
        padding: spacing('default');
    }

    &__action .isvg {
        display: block;
    }

    &__action svg {
        display: block;
    }

    &__action svg path {
        stroke: var(--secondary-alternate);
    }
}