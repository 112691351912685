/* stylelint-disable selector-class-pattern */
@import '../../styles/import';

.signUpWithBenifits {
  @include font('barlow', 'xxl', 'xl', 'heavy');
}

.signUpWithBenifitsGrid {
  padding: spacing('7xl') 0;
  padding-right: 15%;
  padding-left: 15%;

  @media screen and (max-width: 960px) {
    padding-right: 10%;
    padding-left: 10%;
  }

  @media screen and (max-width: 767px) {
    padding-right: 2%;
    padding-left: 2%;
  }
}

.signUpGrid {
  background: var(--light-grey);
}

.benifitsGrid {
  background: var(--light-grey);
  padding-bottom: spacing('2xl');
  width: 100% !important;
}