@import '../../styles/import';

.back-to-top {
  position: fixed;
  bottom: spacing('lg');
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: opacity 300ms ease-in-out, padding-bottom 200ms ease-in-out, transform 200ms ease-in-out;

  @include desktop() {
    bottom: spacing('lg');
  }

  &--enter,
  &--enter-done,
  &--exit {
    display: block;
  }

  &--enter-active,
  &--enter-done {
    opacity: 1;
  }

  .no-touchevents &:hover {
    transform: translateX(-50%) scale(1.05);
  }
}
