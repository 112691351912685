@import '../../styles/import';

.cf-countdown-timer {
  display: flex;
  justify-content: center;

  .cf-events-countdown & {
    @include media-breakpoint-between('md', 'lg') {
      width: 160px;
      flex-flow: row wrap;
      margin-left: 0 - spacing();
    }
  }

  &__time {
    @include font(barlow, md, lg, heavy);

    display: flex;
    flex-direction: column;
    width: 72px;
    box-sizing: border-box;
    padding: spacing() spacing('md');
    margin: spacing('md') 0;
    background-color: var(--secondary-alternate-transparent);
    color: var(--secondary-alternate);
    text-transform: uppercase;
    border-radius: $border-radius;

    &+& {
      margin-left: spacing();

      @include media-breakpoint-up('xs') {
        margin-left: spacing('lg');
      }

      @include media-breakpoint-up('lg') {
        margin-left: spacing('xl');
      }

      .cf-events-countdown & {
        margin-left: spacing();
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .cf-events-countdown & {
      @include media-breakpoint-between('md', 'lg') {
        &:nth-child(2n + 1) {
          margin-left: 0;
        }

        &:nth-child(-n + 4) {
          margin-bottom: 0;
          margin-top: spacing();
        }
      }
    }
  }

  &__number {
    @include font(barlow, xxxl, xxxxl, heavy);

    display: flex;
    align-self: center;
  }
}