@import '../../../styles/import';
@import '../../../components/hamburger/Hamburger.tokens';

.nav-bar {
  @include z-index('layout-header');

  position: relative;
  font-family: font-family('barlow');

  &__body {
    @include z-index('above-layout-header');

    position: relative;
    padding: 18px 0 16px;

    // padding: spacing('default') 0;
    // background-color: var(--purple);
    // background-color: #92278f;

    // background-color: var(--secondary);

    // @include desktop() {
    //     padding: spacing('md') 0;
    // }

    @each $theme in $themes {
      &--#{$theme} {
        @if $theme == 'travel' {
          background-image: var(--event-#{$theme}-nav-background-image-url);
          background-repeat: no-repeat;
          background-size: cover;
        } @else {
          background-color: var(--event-#{$theme}-nav-background-color);
        }
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 15px 0 14px;
    }

    @media screen and (orientation: landscape) and (max-device-width: 1000px) {
      padding: 9px 0 7px;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    line-height: 0;
    position: relative;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__center {
    display: none;

    @include desktop() {
      display: flex;
      align-items: center;
      width: auto;
      max-width: 600px;
    }

    .search-body {
      width: 100vw;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      top: 140px;
      padding: spacing('lg');
      height: 400px;
      overflow: scroll;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__hamburger {
    &.cf-hamburger {
      margin-right: spacing('lg');
      margin-left: -($hamburger-padding-x);

      @include desktop() {
        margin-right: spacing('lg');
      }
    }

    &--mobile.cf-hamburger {
      @include desktop() {
        display: none;
      }
    }

    &--desktop.cf-hamburger {
      display: none;

      @include desktop() {
        display: block;
      }
    }
  }

  &__logo {
    display: inline-block;
    height: 32px;
    margin-right: 5px;
  }

  &__event-logo {
    height: 60px;
    margin-right: 5px;

    @media screen and (max-width: 1200px) {
      height: 56px;
    }

    @media screen and (max-width: 992px) {
      height: 52px;
    }
  }

  &__partner-wrapper {
    height: 72px;
    position: relative;
    left: -10px;

    @media screen and (max-width: 1200px) {
      height: 56px;
      left: -7px;
    }

    @media screen and (max-width: 992px) {
      height: 44px;
    }
  }

  &__partner-container {
    height: 49px;
    width: 98px;
    background-size: contain;
    background-repeat: no-repeat;
    color: var(--white);
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1200px) {
      height: 39px;
      width: 78px;
      font-size: 8.5px;
    }
  }

  &__partner-content {
    position: relative;
    top: 28%;
    left: 8%;

    @media screen and (max-width: 1200px) {
      left: 11%;
    }
  }

  &__partner-logo {
    height: 16px;
    margin-top: 7px;
    margin-left: 8px;

    @media screen and (max-width: 1200px) {
      height: 13px;
      margin-top: 6px;
      margin-left: 4px;
    }
  }

  &__link {
    @include font('barlow', 'xl', 'xl', 'medium');

    color: var(--white);
    margin: 0 0 0 spacing('xl');
    white-space: nowrap;
    text-decoration: none;

    @include desktop() {
      margin: 0 0 0 spacing('3xl');

      // &:not(.nav-bar__link--is-icon):not(.nav-bar__link--avatar) {
      //     @include underline-on-hover();
      // }
    }

    &-join {
      font-size: 18px;
      line-height: 21px;

      @media screen and (max-width: 1200px) {
        font-size: 14px;
        line-height: 16.5px;
      }

      @media screen and (max-width: 576px) {
        display: none !important;
      }

      @each $theme in $themes {
        &.nav-bar__link.nav-bar__link--#{$theme} {
          color: var(--event-#{$theme}-signup-cta-text-color);
        }
      }
    }

    &--is-icon {
      line-height: 0;
      display: inline-block;
      padding: spacing('xs');
      margin-right: -(spacing('default') * 0.5);

      &:hover {
        color: var(--white);
      }

      svg {
        height: 24px;
        width: 24px;
      }

      svg path {
        stroke: var(--white);
      }
    }

    &--avatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--sports {
      color: var(--event-sports-secondary-alternate);
    }
  }

  &__link-login:hover {
    transform: scale(1.02);

    // color: var(--white);
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  @each $theme in $themes {
    &__link--#{$theme}:hover {
      color: var(--event-#{$theme}-login-cta-text-color);
    }
  }

  &__link-join:hover {
    @include underline-on-hover();

    & {
      color: var(--white);
    }
  }

  &__login-button {
    padding: 14px;
    display: flex;
    border-radius: 4px;

    // background-color: #fff200;
    font-size: 18px;
    line-height: 21px;

    @each $theme in $themes {
      &.nav-#{$theme} {
        background-color: var(--event-#{$theme}-login-cta-background-color);
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 12px;
      font-size: 14px;
      line-height: 16.5px;
    }

    &-icon {
      display: flex;
      flex-direction: column;
      align-items: center;

      // border-color: #2e3192;

      @each $theme in $themes {
        &.nav-#{$theme} {
          border-color: var(--event-#{$theme}-login-cta-avatar-color);
        }
      }

      &-top {
        width: 12px;
        height: 12px;
        border: 2px solid;
        border-radius: 50%;
        border-color: inherit;
      }

      &-bottom {
        width: 20px;
        height: 10px;
        border-top: 2px solid;
        border-left: 2px solid;
        border-right: 2px solid;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-top: 2px;
        border-color: inherit;
      }
    }

    &-text {
      margin: auto;
      padding-left: 12px;

      // color: #2e3192;

      @each $theme in $themes {
        &.nav-#{$theme} {
          color: var(--event-#{$theme}-login-cta-text-color);
        }
      }

      @media screen and (max-width: 992px) {
        padding-left: 10px;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.nav-bar-overlay {
  @include z-index('below-layout-header');

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 20%);
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 300ms,
    visibility 0s 300ms;

  &--is-active {
    opacity: 1;
    visibility: visible;
    transition:
      opacity 300ms,
      visibility 0s;
  }
}

.mobile-nav {
  font-family: font-family('barlow');
}
