@import '../../styles/import';
@import 'Hamburger.tokens';
@import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers';

.cf-hamburger.hamburger {
  line-height: 0;
  outline: 0;
  background: none;

  &,
  &.is-active {
    .hamburger-inner {
      /* stylelint-disable-next-line no-descending-specificity */
      &,
      &::before,
      &::after {
        transition-property: all;
        transition-duration: 300ms !important;
      }
    }
  }

  &:hover:not(.is-active) {
    .hamburger-inner::before {
      @include desktop() {
        transform: translateY(-2px);
      }
    }

    .hamburger-inner::after {
      @include desktop() {
        transform: translateY(2px);
      }
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.hamburger--arrowalt {
    &.is-active {
      .hamburger-inner::before {
        transform: translate3d(-4.8px, -3.3px, 0) rotate(-45deg) scale(0.5, 1);
      }

      .hamburger-inner::after {
        transform: translate3d(-4.8px, 3.3px, 0) rotate(45deg) scale(0.5, 1);
      }
    }
  }
}
