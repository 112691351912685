@function z-index($level) {
  /* stylelint-disable-next-line scss/no-global-function-names */
  $fetched-value: map-get($z-index, $level);

  /* stylelint-disable-next-line scss/at-if-no-null */
  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'z-index `#{$level}` not found. Available indicies: #{available-names($font-sizes)}';
  }
}

@mixin z-index($level) {
  z-index: z-index($level);
}
