body {
    font-size: 16px;
    line-height: 1.15rem;
    font-family: font-family('barlow');
    font-weight: 400;
    color: var(--body);
}

h1,
.h1 {
    @include font(barlow, xl, xl, heavy);

    margin: spacing(xl) 0 0;
}

h2,
.h2 {
    @include font(barlow, lg, lg, heavy);

    margin: spacing(lg) 0 0;
}

h3,
.h3 {
    @include font(barlow, md, md, regular);

    margin: spacing(md) 0 0;
}

h4,
.h4 {
    @include font(catamaran, md, md, regular);

    margin: spacing(default) 0 0;
}

h5,
.h5 {
    @include font(catamaran, md, md, regular);

    margin: spacing(xs) 0 0;
}

h6,
.h6 {
    @include font(catamaran, md, md, regular);

    margin: spacing(xs) 0 0;
}

p,
.p {
    @include font(catamaran, md, md, regular);

    margin: spacing(xs) 0 0;
}

* {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}