@import '../../styles/import';

$theme-colours:
    --primary,
    --secondary,
    --secondary-dark,
    --secondary-heavy,
    --secondary-black,
    --secondary-light,
    --secondary-alternate,
    --secondary-alternate-bright,
    --error,
    --white,
    --white-shadow,
    --ivory,
    --bright-grey,
    --light-grey,
    --clear-grey,
    --body,
    --black,
    --shadow,
    --dark-shadow,
    --hover-shadow;

.styleguide-colours {
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        border: 1px solid var(--shadow);
        padding: spacing(default);
        margin-top: spacing(lg);
    }

    &__swatch {
        height: spacing('6xl');
        border: solid 1px transparent;

        @each $colour in $theme-colours {
            &#{ $colour } {
                background: var($colour);
            }
        }
    }
}
