@import '../../styles/import';

.icon-button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: spacing('xs');
    line-height: 1;

    span,
    svg {
        display: block;
    }
}
