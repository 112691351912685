@import '../../styles/import';

.federated-login {
    padding-top: spacing('6xl');

    &__or {
        @include font('barlow', 'xl', $weight: 'medium');

        margin-top: spacing('3xl');
        padding-bottom: spacing('xs');
        text-transform: uppercase;
    }

    .social-button+.social-button {
        margin-top: spacing('lg');
    }
}