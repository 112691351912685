@import '../../styles/import';

.modal-body {
    $self: &;

    overflow: hidden;
    position: relative;

    &--padding-default {
        @include desktop() {
            margin-left: -(spacing('xl'));
            margin-right: -(spacing('xl'));
        }
    }

    &--padding-large {
        @include desktop() {
            margin-left: -(spacing('6xl'));
            margin-right: -(spacing('6xl'));
        }
    }

    &__content {
        padding: 0 $flexboxgrid-gutter-width spacing('6xl');
        box-sizing: border-box;

        @include desktop() {
            padding: 0;
        }

        #{$self}--padding-default & {
            @include desktop() {
                padding-left: spacing('xl');
                padding-right: spacing('xl');
            }
        }

        #{$self}--padding-large & {
            @include desktop() {
                padding-left: spacing('6xl');
                padding-right: spacing('6xl');
            }
        }
    }
}
