@import '../../../styles/import';

.cf-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  color: white;

  &.is-open-menu {
    z-index: 101;
  }

  .inner {
    padding: 15px 0;
  }

  .logo {
    display: block;
    width: 82px;
    height: auto;
    margin-right: 52px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .partner-logo {
    display: block;
    width: 100px;
    height: auto;
    margin-right: 52px;

    img {
      width: 100%;
      height: auto;
    }
  }

  a {
    color: white;
    transition: all 0.25s ease-out;

    &:hover {
      color: rgb(255 255 255 / 70%);
    }
  }
}

.cf-sign-in {
  font-size: 14px;

  > .cf-flex {
    gap: 24px;
  }

  .cf-avatar {
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 6px;

    > img {
      width: 100%;
      height: auto;
    }
  }

  @include media-breakpoint-down('md') {
    display: none;
  }
}

.cf-menu {
  font-size: 14px;

  ul {
    gap: 32px;
    margin: 0;

    li {
      list-style: none;
    }
  }

  @include media-breakpoint-down('md') {
    display: none;
  }
}

.cf-menu-btn-sm {
  display: none;

  @include media-breakpoint-down('md') {
    display: flex;
  }
}
