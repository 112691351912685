@import '../../../styles/import';

.search-default-content {
    display: none;

    &--visible {
        display: block;
    }

    @include media-breakpoint-down(md) {
        margin: 0 spacing('md');
    }
}
