/* stylelint-disable selector-class-pattern */
@import '../../styles/import';

.partners {
  @include font('barlow', 'xxl', 'xl', 'heavy');

  text-align: left;
  background-color: var(--white);
  border-top: 1px solid var(--light-grey);

  &____heading {
    @include font('barlow', 'xl', 'lg', 'heavy');
  }

  &--variation-under-full-width {
    margin-top: 0;
    border-top-style: none;
  }

  &--variation-non-event {
    margin: 0;
    padding-bottom: 0;
    display: block;
  }

  &--logos {
    display: none;

    @media screen and (min-width: 768px) {
      margin-top: spacing('xl');
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &--partner-image {
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--partner-text {
    @include font(barlow, sm, sm, regular);

    width: 100px;
    height: 40px;
    margin-top: spacing('default');
    color: var(--black);
    hyphens: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &--row {
    display: flex;
    max-width: 100%;
    flex-flow: row wrap;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &--partner-link {
    margin-right: auto;
    width: 150px;
    height: 95px;
    border: 1px solid var(--silver);
    border-radius: 4px;
    position: relative;
    margin-bottom: spacing('default');

    @include desktop() {
      width: 167.28px;
      height: 95px;
      margin-right: spacing('lg');
    }
  }

  &--partner-link-in-carousel {
    margin: 0;
    width: 100%;
    display: inline-block;
  }

  &--partner-logo {
    max-width: 150px;
    max-height: 80px;
  }

  .carousel {
    margin: 0;

    .partners--partner-link-in-carousel {
      width: 95%;
    }

    .partners--partner-logo {
      width: 100%;
    }
  }

  .slick-list {
    margin: 0 20px;
    border-radius: 10px;
  }

  .carousel__arrow {
    width: 20px;
    height: 20px;

    svg {
      height: 7px;
      width: 4px;
    }
  }

  .carousel__arrow--direction-prev {
    left: 0;
  }

  .carousel__arrow--direction-next {
    right: 0;
  }

  &--sponsor-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: spacing('default');
    width: 100%;

    &-image {
      max-width: 100%;
      object-fit: contain;
    }
  }
}