@import '../../styles/import';

.filter-by-brand {
    display: flex;
    height: 100%;

    &__checkbox-name {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;

        &--no-logo {
            @include font('barlow', 'md', $weight: 'medium');
        }
    }

    &__checkbox-name-image {
        margin-right: spacing('xl');

        @include mobile() {
            display: none;
        }
    }

    &__checkbox-name-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: spacing('lg');

        @include desktop() {
            padding-right: 0;
        }
    }

    @include mobile() {
        .gradient-container {
            margin-left: ($flexboxgrid-gutter-width * -1);
            margin-right: ($flexboxgrid-gutter-width * -1);

            &__content {
                padding-left: $flexboxgrid-gutter-width;
                padding-right: $flexboxgrid-gutter-width;
            }
        }
    }
}