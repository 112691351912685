@import '../../styles/import';

.logo-wall {
    overflow: hidden;

    // Extra specificity needed to override grid CSS
    &__row.row {
        @include media-breakpoint-up('md') {
            margin-left: -20px;
            margin-right: -20px;
        }

        @include media-breakpoint-up('lg') {
            margin-left: -35px;
            margin-right: -35px;
        }
    }

    // Extra specificity needed to override grid CSS
    &__col[class^='col-'] {
        margin-top: 20px;

        @include media-breakpoint-up('md') {
            padding-left: 20px;
            padding-right: 20px;
            margin-top: 40px;
        }

        @include media-breakpoint-up('lg') {
            padding-left: 35px;
            padding-right: 35px;
            margin-top: 70px;
        }
    }
}
