@import '../../../../styles/import';

.home-header {
  display: flex;
  flex-direction: column;
  font-family: font-family('barlow');

  // background-color: var(--light-gray-background);

  @each $theme in $themes {
    &--#{$theme} {
      background-image: linear-gradient(#908aa259, var(--event-#{$theme}-home-header-background-color));
    }
  }



  &__logo-wrapper {
    order: 2;
    margin-top: spacing('xl');
    display: flex;
    justify-content: center;
    align-items: center;

    @each $theme in $themes {
      &--#{$theme} {
        background: var(--event-#{$theme}-background-colour);
      }
    }

    @include mobile() {
      display: none;
    }

    @include desktop() {
      order: 1;
      margin-top: 0;
    }
  }

  &__logo-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 100%;
    width: 100%;
  }

  &__banner {
    order: 1;
    display: flex;
    align-items: center;

    @include desktop() {
      order: 2;
    }
  }

  &__banner-section {
    order: 2;
    margin-top: spacing('xl');

    @include desktop() {
      order: 1;
    }
  }

  &__categories {
    order: 1;

    @include desktop() {
      order: 2;
    }
  }

  &__logo {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &__category-timer {
    @media screen and (max-width: 768px) {
      padding-bottom: 20px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.isHidden {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}