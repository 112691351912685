@import '../../../styles/import';

.second-nav {
  width: 100%;
  height: 60px;

  // background-color: #2e3192;

  @each $theme in $themes {
    &__#{$theme} {
      @if $theme =='travel' {
        background-image: var(--event-#{$theme}-second-nav-background-image);
      }

      @else {
        background-color: var(--event-#{$theme}-second-nav-background-color);
      }
    }
  }

  @media screen and (orientation: landscape) and (max-device-width: 1000px) {
    height: 40px;
  }

  &__container {
    display: flex;
    column-gap: 6%;

    // align-items: center;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .mobile-search-bar {
    @media screen and (min-width: 768px) {
      display: none;
    }

    .search-bar {
      width: 100%;

      /* stylelint-disable-next-line selector-class-pattern */
      .search-bar__input-wrapper {
        border: none;
        padding: 0 30px 0 20px;
        border-radius: 0;
        height: 60px;

        @media screen and (orientation: landscape) and (max-device-width: 1000px) {
          height: 40px;
        }
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .search-bar__icon {
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}