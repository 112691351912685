/** CF GRID - 2023 **/
.cf-flex {
  display: flex;
  flex-wrap: nowrap;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.gap-xxl {
  gap: 24px;
}

.gap-y-32 {
  row-gap: 32px;
}

.gap-x-16 {
  column-gap: 16px;
}

.row {
  &.cf-flex {
    flex-wrap: nowrap;
    margin-right: 0;
    margin-left: 0;
  }
}
