/**
 ** DEAL BROWSE CSS
 **/
@import '../../../../../styles/import';

.cf-deal-browse {
    .head-blk {
        margin-bottom: 44px;
    }

    .cf-box {
        transition: opacity 0.2s ease-out;
        opacity: 1;

        &.sorting {
            opacity: 0.5;
        }

        .deals-list {
            min-height: calc(324px * 2 + 32px);
        }
    }

    .cta-blk {
        padding-top: 80px;
    }

    .tabs-blk {
        gap: 5px;
    }

    @include media-breakpoint-down('xlg') {
        .cf-box {
            display: flex;
            align-items: center;
            flex-direction: column;

            .cf-flex {
                max-width: calc(262px * 3 + 16px * 2);
            }
        }
    }

    @media (max-width: 900px) {
        .cf-box {
            .cf-flex {
                max-width: calc(262px * 2 + 16px * 1);
            }
        }
    }

    @include media-breakpoint-down('xs') {
        .cf-box {
            .cf-flex {
                max-width: calc(100vw - 40px);
            }
        }
    }

    @include media-breakpoint-down('xxs') {
        .gap-y-32 {
            row-gap: 24px;
        }

        .head-blk {
            margin-bottom: 34px;

            &.cf-flex {
                &.flex-row {
                    flex-direction: column;
                    align-items: start;
                    gap: 24px;
                }
            }

            .ttl-blk {
                width: 100%;
                text-align: center;
            }
        }

        .cf-box {
            .cf-flex {
                display: flex;
                justify-content: center;
            }
            
            .deals-list {
                min-height: 330px;
            }
        }

        .cta-blk {
            padding-top: 64px;
        }

        .sm-scroll {
            width: calc(100vw - 20px);
            display: flex;
            flex-flow: row nowrap;
            flex: none;
            align-items: center;
            overflow: auto;
            height: 36px;
        }

        .tabs-blk {
            gap: 6px;
            width: calc(140px * 3 + 12px);

            .button {
                &--size-small {
                    height: 36px;
                    width: 140px;
                    padding: 0;
                    font-size: 14px;
                }
            }
        }
    }
}