@import '../../styles/import';

$max-height: 850px;

.interstitial-page {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    background: var(--white);

    @include desktop() {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__grid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 spacing('lg') spacing('lg');
    box-sizing: border-box;
    max-height: 100vh;
    overflow: hidden;

    @include desktop() {
      height: 100vh;
      max-height: 900px;

      @media (max-height: $max-height) {
        max-height: 700px;
      }
    }
  }

  &__logo-wrapper {
    margin-bottom: spacing('default');

    @each $theme in $themes {
      &--#{$theme} {
        background: var(--event-#{$theme}-background-colour);
      }
    }
  }

  &__event-logo {
    display: block;
    height: 100px;
    padding: 0 spacing('default');

    @include desktop() {
      height: auto;
      width: 300px;
      padding: spacing('xs') spacing('md');

      @media (max-height: $max-height) {
        height: 150px;
        width: auto;
      }
    }
  }

  &__brand-logo {
    display: block;
    width: 80px;
    margin-bottom: spacing('xl');

    @include desktop() {
      width: 120px;

      @media (max-height: $max-height) {
        width: 100px;
      }
    }
  }

  &__redirection-notice {
    @include font('barlow', 'xl', 'lg');
  }

  &__countdown {
    @include font('barlow', 'xxxxxl', 'xxxxxl', 'medium');
  }

  &__failure-text {
    @include font('barlow', 'md', 'lg');

    margin-bottom: spacing('xl');
  }

  &__retry-link {
    @include underline-on-hover();

    & {
      color: var(--primary);
    }
  }

  &__partners {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__partner {
    display: block;
    width: 100px;
    max-width: 100%;
    height: auto;
    margin: spacing('lg') auto;

    @include desktop() {
      width: 200px;

      @media (max-height: $max-height) {
        width: 150px;
      }
    }

    & + & {
      margin-left: spacing('xl');
    }
  }

  &__partner-banner {
    display: inline-block;
    max-width: 100%;
  }

  &__laybuy-logo {
    width: 300px;

    @media screen and (max-width: 768px) {
      width: 203px;
    }
  }
}
