/** NUMBERs */
@import '../../../../../styles/import';

.cf-numbers-blk {
    padding: 112px 0;
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    .content {
        width: 410px;

        .ttl {
            color: white;
            margin: 0;
        }

        .cta-blk {
            padding-top: 42px;
        }
    }

    @include media-breakpoint-down('md') {
        > .cf-flex {
            &.flex-row {
                flex-direction: column-reverse;
            }
        }

        .content {
            text-align: center;
    
            .cta-blk {
                > .button {
                    margin: 0 auto;
                }
            }
        }
    }
    
    @include media-breakpoint-down('xs') {
        padding: 80px 0;

        .content {
            width: auto;
            max-width: 350px;

            .desc {
                padding: 0 10px;
            }
        }

        .cta-blk {
            .display-desktop {
                display: none;
            }
        }
    }
}

.display-numbers {
    .cf-flex {
        gap: 32px;
        padding-right: 80px;
    }

    @include media-breakpoint-down('lg') {
        .cf-flex {
            gap: 24px;
            padding-right: 0;
        }
    }

    @include media-breakpoint-down('md') {
        .cf-flex {
            gap: 0;
            padding-right: 0;
        }
    }

    @include media-breakpoint-down('xs') {
        position: relative;
        height: 345px;
        width: 100%;
        max-width: 390px;
    }
}

.numbers-item {
    position: relative;
    text-align: center;

    &.first-item {
        width: 204px;
        height: 240px;
        margin-top: 105px;
    }

    &.second-item {
        width: 201px;
        margin-top: 25px;
    }

    .big-nb {
        font-size: 68px;
        line-height: 130%;
    }

    svg {
        position: absolute;
        top: -25px;
        left: 44px;
    }

    @include media-breakpoint-down('md') {
        .cf-flex {
            gap: 0;
            padding-right: 0;
        }

        &.second-item {
            > svg {
                transform: scaleX(-1);
            }
        }
    }

    @include media-breakpoint-down('xs') {
        position: absolute;

        &.first-item {
            width: 183px;
            height: 217px;
            margin-top: 0;
            bottom: 0;
            left: 0;
        }

        &.second-item {
            width: 204px;
            height: 208px;
            margin-top: 0;
            top: 0;
            right: 0;
        }

        .big-nb {
            font-size: 60px;
        }

        .txt {
            font-size: 15px;
        }

        svg {
            width: 130px;
            height: auto;
        }
    }
}