@include media-breakpoint-down(md) {
    [class^='col-xs'] {
        padding-right: 6px;
        padding-left: 6px;
    }

    .row {
        margin-left: -6px;
        margin-right: -6px;
    }
}

// @media only screen and (max-width: 767px) {
@include media-breakpoint-down(xs) {
    [class^='col-xs'] {
        padding-right: 8px;
        padding-left: 8px;
    }

    .row {
        margin-left: 12px;
        margin-right: 12px;
    }
}
