@import '../../../styles/import';

$breakpoint-big-phones: xs;

.featured-brands-panel {
    @include media-breakpoint-down($breakpoint-big-phones) {
        margin-top: spacing('2xl');
    }

    &__wrapper {
        position: relative;
    }

    &__heading {
        @include font('barlow', 'xl', 'lg', 'heavy');

        font-size: 30px;
        margin: 20px 0;
        text-align: left;
    }

    &__footer {
        @include font('barlow', 'md', 'sm', 'heavy');

        color: var(--body-light);
        text-align: center;
        margin-top: spacing('3xl');

        @include desktop() {
            margin-top: spacing('6xl');
        }
    }
}