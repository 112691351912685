@import '../../styles/import';

.select {
    @include font('barlow', 'md', 'sm');

    width: 100%;
    box-sizing: border-box;
    margin-top: spacing('2xl');
    border: 1px solid var(--light-grey);
    padding: spacing('md') spacing('5xl') spacing('md') spacing('md');
    border-radius: 2px;
    color: var(--body);
    appearance: none;
    background-color: var(--white);
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M7%208L12%203L17%208%22%20stroke%3D%22%23C3C3C3%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M17%2016L12%2021L7%2016%22%20stroke%3D%22%23C3C3C3%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: center right spacing('default');

    &--empty {
        color: var(--clear-grey);
    }

    &:focus {
        border-color: var(--secondary-alternate);
    }

    &--error {
        border-color: var(--error);
    }

    &::-ms-expand {
        display: none;
    }

    &__error-message {
        @include font('barlow', 'xs', 'xs');

        margin-top: spacing('default');
        color: var(--error);
    }
}