/***
 ** Container Style
 **/

.cf-container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  
  &.max-w-1320 {
    max-width: 1360px;
  }
}

