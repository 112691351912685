@import '../../styles/import';

.welcome {
    &__title {
        @include font('barlow', 'xxl', 'xl', 'medium');

        margin-top: spacing('6xl');
        text-align: center;

        @include desktop() {
            margin-top: spacing('7xl');
        }
    }

    &__image {
        margin: spacing('6xl') auto 0;
    }

    &__description {
        @include font('barlow', 'md', 'lg');

        text-align: center;
        margin-top: spacing('6xl');
    }
}