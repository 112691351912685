@import '../../styles/import';

/* stylelint-disable-next-line selector-class-pattern */
.threeColumnSignup {
  @include font('barlow', 'xxl', 'xl', 'heavy');

  text-align: center;
  background-color: var(--purple);
  padding-top: spacing('6xl');
  padding-bottom: spacing('6xl');

  @include desktop() {
    padding-top: spacing('7xl');
    padding-bottom: spacing('7xl');
  }

  &__image-wrapper {
    border-radius: 50%;
    overflow: hidden;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 80px;
    height: 80px;
    max-height: 100%;
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: spacing('6xl');
    padding: 0 spacing('default');
  }

  &__item-title {
    @include font('barlow', 'xl', 'lg', 'medium');

    color: var(--black);
    max-width: 400px;
    padding: spacing('xl') spacing('lg') 0;
    text-align: center;

    @include desktop() {
      padding: spacing('xl') 0 0;
    }
  }

  &__item-body {
    @include font('barlow', 'md', 'lg', 'regular');

    color: var(--black);
    max-width: 400px;
    padding: spacing('lg') spacing('lg') 0;
    text-align: center;

    @include desktop() {
      padding: spacing('lg') 0 0;
    }
  }

  &__item-footer {
    @include font('barlow', 'md', 'lg', 'medium');

    color: var(--white);
    max-width: 360px;
    padding: spacing('default') spacing('lg') 0;

    @include desktop() {
      padding: spacing('default') 0 0;
    }
  }
}