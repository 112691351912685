@import '../../../styles/import';

.brand-page-heading {
    &__title {
        @include font(barlow, xl, lg, heavy);

        margin-top: spacing('3xl');
        color: var(--body);

        @include desktop() {
            @include font(barlow, xxxl, xxxl, heavy);
        }
    }
}