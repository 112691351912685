@import '../../../styles/import';

.search-categories {
  margin: 0;
  padding: 0;
  list-style: none;

  &__link-wrapper {
    display: block;

    & + & {
      margin-top: spacing('2xl');
    }
  }

  &__link {
    @include font('barlow', 'md', $weight: 'medium');
    @include underline-on-hover();

    & {
      color: var(--primary);
      display: inline-block;
    }
  }
}
