/* LANDING PAGE - SYSTEM */
@import '../../../styles/import';
@import '../../../styles/utils/index';

.cf-layout {
    font-family: Barlow, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: var(--cf-gray3);
}

.cf-section {
    padding-top: 96px;
    padding-bottom: 96px;

    &.bg-light-violet {
        &.deals-section {
            padding-top: 146px;
        }

        .cf-flex.flex-col {
            gap: 128px;
        }
    }

    @include media-breakpoint-down('xs') {
        padding-top: 80px;
        padding-bottom: 80px;

        &.bg-light-violet {
            .cf-flex.flex-col {
                gap: 80px;
            }
        }
    }
}

@include media-breakpoint-down('xxs') {
    .cta-blk {
        .button {
            max-width: 350px;
            width: 100%;
        }
    }
}