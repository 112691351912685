@import '../../styles/import';

.section-header {
    margin-top: spacing('lg');

    &__title {
        margin: 0;
        font-family: font-family('barlow');
        font-size: 28px;
        line-height: 32px;
        font-weight: 600;

        @media screen and (max-width: 768px) {
            font-size: 24px;
            line-height: 28px;
        }
    }
}