@import '../../../styles/import';

.sub-nav {
  @include z-index('layout-header');

  background: var(--secondary-light);

  @each $theme in $themes {
    &--#{$theme} {
      background: var(--event-#{$theme}-hamburger-menu-background-colour);
    }
  }

  & {
    color: var(--white);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    visibility: hidden;
    transform: translateX(-100%);
    transition:
      transform 300ms,
      visibility 0s 300ms;
    height: calc(100vh - var(--header-height));
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  @include desktop() {
    transform: translateY(-100%);
    height: auto;
  }

  &--is-active {
    visibility: visible;
    transform: translate(0, 0);
    transition:
      transform 300ms,
      visibility 0s;
  }

  &__items {
    list-style: none;
    padding: 0;
  }

  &__item {
    border-bottom: solid 1px var(--white-shadow);

    &:last-child {
      border-bottom-style: none;
    }

    @include desktop() {
      border-bottom-style: none;
    }
  }

  &__link {
    @include font('barlow', 'md', 'xxxxl', 'medium');
    @include underline-on-hover($display: block, $pseudo-element: 'after');

    & {
      position: relative;
      color: var(--white);
      text-decoration: none;
    }

    @include desktop() {
      max-width: 75%;
      line-height: line-height('xl');
      margin: spacing('2xl') 0;

      &::after {
        width: calc(100% + spacing('md')); // 12px is right offset of icon below
      }
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include desktop() {
      right: -spacing('md');
    }

    path {
      stroke: var(--white);
    }
  }

  &__text {
    overflow: hidden;
    display: block;
    height: line-height('xxxxl');

    @include desktop() {
      height: line-height('xl');
    }
  }
}
