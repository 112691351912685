@import '../../../styles/import';

.c-cell {
    width: 106px;
    background-color: rgb(255 255 255 / 30%);
    border-radius: 16px;
    text-align: center;
    padding: 34px 18px 14px;
    line-height: 1;

    .c-number {
        @include font('barlow', 'lg', 'sm', 'regular');

        font-size: 42px;
        margin-bottom: 34px;
    }

    .c-txt {
        @include font('barlow', 'lg', 'sm', 'regular');

        font-size: 16px;
    }

    @include media-breakpoint-down('xs') {
        width: 76px;
        height: 88px;
        padding: 16px 0 0;

        .c-number {
            font-size: 30px;
            margin-bottom: 24px;
        }

        .c-txt {
            font-size: 12px;
        }
    }
}