@import '../../styles/import';

.dropdown {
    $self: &;

    position: relative;

    &__label {
        @include font('barlow', 'md', $weight: 'medium');

        background: none;
        border: 0;
        display: flex;
        cursor: pointer;
        color: var(--secondary-alternate);
        padding: spacing('default') 0;
    }

    &__label-desktop {
        @include mobile() {
            display: none;
        }
    }

    &__label-mobile {
        @include desktop() {
            display: none;
        }
    }

    &__label-icon {
        margin-left: spacing('default');

        @include mobile() {
            display: none;
        }
    }

    &__label-icon .isvg {
        display: block;
        width: 10px;
    }

    &__label-icon svg {
        transform: rotate(90deg);
        transform-origin: center;

        #{$self}--open & {
            transform: rotate(-90deg);
        }
    }

    &__label-icon svg path {
        stroke: var(--secondary-alternate);
    }
}