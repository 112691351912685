@import '../../styles/import';

.modal-secondary-layer {
    @include z-index('above-overlay');

    position: fixed;
    inset: 0;
    background: var(--white);
    transform: translateX(100%);
    transition: transform 300ms, box-shadow 300ms;
    display: flex;
    flex-direction: column;

    &--open {
        transform: translateX(0);
        box-shadow: $hoverShadow;
    }
}
