/* stylelint-disable selector-class-pattern */
@import '../../styles/import';

.category_page__breadcrumb-section {
  margin-top: 30px;
}

.category-deal-list {
  @include desktop() {
    margin-top: spacing('xl');
  }
}

.section-header {
  &__title {
    .section-header--category & {
      margin-top: spacing('xl');
      font-size: 24px;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .section-header--more & {
      margin-top: spacing('xl');
    }
  }
}
