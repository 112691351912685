@import '../../styles/import';

.progress-bar {
    display: block;
    margin: 0 auto;

    &__background {
        width: 100%;
        height: spacing('xs');
        background: var(--ivory);
        margin-top: spacing('xl');
        overflow: hidden;
        position: relative;
        border-radius: spacing('2xs');
    }

    &__progress {
        height: spacing('xs');
        background: var(--secondary-alternate);
        left: 0;
        position: absolute;
        top: 0;
        border-radius: spacing('2xs');
    }
}
