@import '../../styles/import';

.banner-ad {
    margin-top: spacing('7xl');

    &__banner-wrapper {
        @include media-breakpoint-down('xs') {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
