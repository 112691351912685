@import '../../../styles/import';
/* stylelint-disable-next-line import-notation */
@import url('https://fonts.googleapis.com/css?family=Kalam&display=swap');

$sans-serif: sans-serif;
$handwriting: 'Kalam', cursive;
$light-blue: #00a7e5;
$white: #fff;
$grey: #989898;
$bg-grey: #f7f7f7;
$light-grey: #f0f0f0;

/* stylelint-disable-next-line selector-class-pattern */
.FinalistPage {
  background-color: $bg-grey;
  padding-top: 0;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  &__head {
    background: $white;
  }

  &__logo {
    padding: 70px 0 0;
    text-align: center;
  }

  &__title {
    text-align: center;
    background-image: linear-gradient(to top, $light-grey 0%, rgb(244 244 244 / 0%) 100%);
    padding: 90px 0 200px;

    h1 {
      font-size: 48px;
      font-weight: 300;
      text-transform: uppercase;
      max-width: 730px;
      margin: 0 auto;
      line-height: 1.1;
      font-family: inherit;

      strong {
        font-weight: 900;
      }

      @media only screen and (max-width: 767px) {
        font-size: 36px;
      }
    }
  }
}

.layout {
  font-family: barlow, $sans-serif;

  p,
  .p {
    font-family: barlow, $sans-serif;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.MainPage {
  background-color: $bg-grey;

  .button,
  button {
    background: $white !important;
    border: 1px solid $light-blue !important;
    border-radius: 25px !important;
    padding: 14px 22px !important;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
    color: $light-blue !important;
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: $white !important;
      background: $light-blue !important;
      box-shadow: 0 16px 32px 0 rgb(0 167 229 / 28%);
      text-decoration: none;
    }

    @media only screen and (max-width: 767px) {
      padding: 10px 25px;
    }
  }

  &__wrapper {
    position: relative;
    top: -150px;
    margin-bottom: -120px;

    h4 {
      font-size: 22px;
      margin-bottom: 25px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .FinalistList {
      h3 {
        text-align: center;
        margin-bottom: 30px;
        margin-top: 0;
        font-family: inherit;
        font-size: 1.75rem;
      }

      .row {
        justify-content: center;
      }

      .finalist {
        position: relative;

        &__topImage {
          position: absolute;
          top: -10px;
          right: -10px;
          width: 115px;
          transform: rotate(25deg);
        }

        &__wrapper {
          background: $white;
          padding: 5px 40px 5px 5px;
          box-shadow: 0 12px 38px 0 rgb(178 178 178 / 24%);
          margin-bottom: 48px;
          border-radius: 3px;

          @media only screen and (max-width: 767px) {
            padding: 5px 5px 20px;
          }
        }

        &__videoResponsive {
          overflow: hidden;
          padding-bottom: 56.25%;
          position: relative;
          height: 0;

          iframe {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
          }
        }

        &__notepad {
          background-image: url('https://ucarecdn.com/e0882b72-3355-4c27-a110-14d92d714bbc/2NotepadMIDDLEREPEAT.png');
          background-size: contain;
          background-repeat: repeat-y;
          padding: 0 30px;
          margin-top: -5px;
          font-family: $handwriting;

          p {
            font-family: $handwriting;
            margin-bottom: 0;
            padding-bottom: 1rem;
          }

          ul,
          ol {
            margin-top: 0;
          }

          li {
            padding-bottom: 0.5rem;
          }

          a {
            color: $light-blue;
            text-decoration: none;
          }

          a:hover {
            color: $grey;
            text-decoration: underline;
          }
        }

        &__img {
          img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            display: block;
          }
        }

        &__info {
          padding-top: 20px;

          .row {
            margin-right: -8px;
            margin-left: -8px;
          }
        }

        &__meta {
          border-bottom: 1px solid #dadada;
          overflow: hidden;
          margin-bottom: 20px;

          strong {
            float: right;
            font-weight: 500;
          }

          span {
            font-style: italic;
            color: $grey;
          }
        }

        &__btnArea {
          margin-top: 10px;
          margin-bottom: 16px;

          &--padding {
            padding-left: 25px;
          }
        }

        &__moreInfo {
          text-align: right;
        }

        &__hidden {
          padding: 20px;

          .col-xs-12 {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }

        &__less {
          color: $light-blue;
          font-weight: 900;
          text-decoration: underline;
        }

        &__greyTitle {
          color: $grey;
          font-size: larger;
          padding-bottom: 0;
        }
      }
    }
  }
}