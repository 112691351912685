@import '../../styles/import';

.cf-events-countdown {
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--event-background-colour);
    color: var(--event-text-colour);
    padding: spacing('6xl') spacing('xl') spacing('3xl');

    @include media-breakpoint-up('md') {
      border-radius: 28px;
    }

    @each $theme in $themes {
      &--#{$theme} {
        background: var(--event-#{$theme}-background-colour);
        color: var(--event-#{$theme}-text-colour);

        /* stylelint-disable-next-line selector-class-pattern */
        .cf-countdown-timer__time {
          background-color: var(--event-#{$theme}-secondary-alternate-transparent);
          color: var(--event-#{$theme}-text-colour);
        }

        /* stylelint-disable-next-line selector-class-pattern */
        .cf-events-countdown__heading {
          color: var(--event-#{$theme}-text-colour) !important;
        }
      }
    }
  }

  &__logo {
    margin-bottom: spacing();

    img {
      max-width: 100%;
      width: 500px;
    }
  }

  &__heading {
    @include font(barlow, xxl, xxl, heavy);

    margin: spacing('xs') 0;
    color: var(--event-text-colour) !important;
  }

  &__date {
    @include font(barlow, md, xl, medium);
  }

  &__description {
    @include font(barlow, md, lg, regular);

    margin: spacing('lg') 0;
    width: 100%; // IE11 fix
  }

  &__calendar-button {
    margin-top: spacing('xl');
    margin-bottom: 0;
  }
}