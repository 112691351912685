@import '../../styles/import';

.brand-group {
    $self: &;

    // prepending with div to override flexboxgrid row class
    div#{&}__row {
        @include media-breakpoint-down(xs) {
            margin-right: 0;
        }
    }

    // prepending with div to override flexboxgrid row class
    div#{&}__cell {
        @include media-breakpoint-down(xs) {
            padding-right: 0;
        }
    }

    &__name {
        @include font(barlow, xl, sm);

        color: var(--body);
        margin-top: spacing(xl);
        padding-bottom: spacing(lg);
        border-bottom: solid 1px var(--light-grey);

        @include desktop() {
            line-height: line-height('lg');
            margin-top: spacing('7xl');

            @at-root .brand-page-heading+#{$self} & {
                margin-top: spacing('xl');
            }
        }
    }
}