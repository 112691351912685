@import '../../../styles/import';

.search-results-progress {
    display: block;
    margin: 0 auto;
    width: 85%;

    &__text {
        text-align: center;
        color: var(--body);
        opacity: .3;

        @include font('barlow', 'md', 'sm', 'regular');
    }

    &__background {
        width: 100%;
        height: spacing('xs');
        background: var(--ivory);
        margin-top: spacing('xl');
        overflow: hidden;
        position: relative;
        border-radius: spacing('2xs');
    }

    &__progress {
        height: spacing('xs');
        background: var(--secondary-alternate);
        left: 0;
        position: absolute;
        top: 0;
        border-radius: spacing('2xs');
    }
}