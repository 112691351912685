@import '../../styles/import';

.html-block {

  /* stylelint-disable-line */
  &,
  * {
    @include font('barlow', 'md', $weight: 'regular');
  }

  a {

    /* stylelint-disable-line */
    &,
    & * {
      @include font('barlow', 'md', $weight: 'medium');

      color: var(--primary) !important;
      text-decoration: none;
    }
  }

  u {
    text-decoration: none;
  }

  &--font-size-sm {

    /* stylelint-disable-next-line no-descending-specificity */
    * {
      @include font('barlow', 'sm', $weight: 'regular');
    }

    a {

      /* stylelint-disable-line */
      &,
      & * {
        @include font('barlow', 'sm', $weight: 'medium');
      }
    }
  }
}