// Antd Design
@import '~antd/dist/antd';
@import '../../../styles/import';

.ant-input-number {
    width: 100%;
    max-width: 120px;
}

.cf-file-upload {
    &__widget {
        &__upload-button {
            text-align: right;
        }

        &__address {
            h2 {
                margin-bottom: spacing('lg');
            }
        }
    }

    &__fileslist {
        margin-top: spacing('lg');
    }

    h1 {
        margin-bottom: spacing('lg');
    }

    .cfa-image-upload {
        margin-top: spacing('lg');
        background-color: var(--bright-grey);

        &__help {
            text-align: left;
        }
    }
}
