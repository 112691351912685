@import '../../../styles/import';

.search-refine-modal {
    &__trigger {
        @include font('barlow', 'md', $weight: 'medium');

        margin: 0;
        padding: 0;
        background: none;
        border: 0;
        color: var(--secondary-alternate);
    }

    &__list {
        list-style: none;
        margin: 0 ($flexboxgrid-gutter-width * -1);
        padding: 0;
    }

    &__item {
        border: 0;
        margin: 0;
        padding: spacing('xl') 0 spacing('xl') $flexboxgrid-gutter-width;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: url('../../../static/icons/chevron.svg') no-repeat right $flexboxgrid-gutter-width top 50%;
    }

    &__item-label {
        @include font('barlow', 'md', $weight: 'medium');

        color: var(--secondary-alternate);
    }

    &__item-content {
        @include font('barlow', 'md', $weight: 'regular');

        text-align: right;
        padding-right: spacing('7xl');
    }
}