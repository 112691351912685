/* stylelint-disable no-descending-specificity */
@import '../../../styles/import';

.search-bar {
  &:focus {
    background-color: var(--body-background);
  }

  input {
    color: var(--body-background);
  }

  @media screen and (min-width: 1080px) {
    width: 500px;
  }

  @media screen and (max-width: 1080px) {
    width: 450px;
  }

  @media screen and (max-width: 992px) {
    width: 320px;
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    padding: 5px 8px;
    border: 1px solid var(--overlay);
    box-sizing: border-box;
    border-radius: 4px;

    @each $theme in $themes {
      &--#{$theme} {
        @if $theme =='travel' {
          background: var(--event-#{$theme}-search-bar-input-wrapper-background-color);
        }
      }
    }

    &:focus-within {
      background-color: var(--body-background);

      input {
        color: var(--body);

        &::placeholder {
          color: var(--body);
        }
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .search-bar__icon {
        svg path {
          stroke: var(--body);
        }

        /* stylelint-disable-next-line selector-class-pattern */
        &.search-bar__icon__cross {
          svg path {
            stroke: var(--body-background);
          }
        }
      }
    }

    input {
      font-size: 16px;
    }
  }

  &__icon {
    display: block;
    align-self: center;
    margin-right: spacing('lg');

    .isvg {
      display: block;
    }

    svg {
      display: block;
      height: 16px;
      width: 16px;

      @include desktop() {
        height: 32px;
        width: 32px;
      }
    }

    svg path {
      stroke: var(--body-background);
    }

    &__cross {
      background: #5c2d91;
      border-radius: 50%;

      svg {
        padding: 5px;
      }

      svg path {
        stroke: #fff;
      }
    }

    @each $theme in $themes {
      &.search-bar__icon-#{$theme} {
        svg path {
          stroke: var(--event-#{$theme}-searchbar-icon-stroke-color);
        }

        @media screen and (max-width: 767px) {
          svg path {
            stroke: var(--event-#{$theme}-searchbar-icon-mobile-stroke-color);
          }

        }
      }
    }
  }

  &__input {
    @include font('barlow', 'xxl', 'xxl', 'medium');

    outline: 0;
    border: 0;
    background: none;
    min-width: 0;
    flex-grow: 1;
    color: var(--body);

    @each $theme in $themes {
      &--#{$theme} {
        &::placeholder {
          color: var(--event-#{$theme}-searchbar-placeholder-text-color);
        }
      }
    }

    @media screen and (max-width: 767px) {
      @each $theme in $themes {
        &--#{$theme} {
          &::placeholder {
            color: var(--event-#{$theme}-searchbar-placeholder-mobile-text-color);
          }
        }
      }
    }

    &:focus {
      &::placeholder() {
        color: var(--light-grey);
      }
    }

    @include desktop() {
      @include font('barlow', 'xxxl', 'xxxl', 'medium');
    }
  }

  &__clear {
    @include mobile() {
      padding: 0 0 0 spacing('default');
    }

    @include desktop() {
      padding: 0;
      margin-right: -(spacing('default'));
    }
  }
}