@import '../../styles/import';

.collection-banner {
  &__image {
    margin: 36px auto 0;
    max-width: 970px;
    width: 100%;
    @media screen and (max-width: 768px) {
      max-width: 320px;
    }

    & img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      aspect-ratio: 97/9;
      @media screen and (max-width: 768px) {
        aspect-ratio: 32/5;
      }
    }
  }
}
