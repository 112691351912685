@import '../../styles/import';
@import '../../components/hamburger/Hamburger.tokens';

.cfreceipt {
    text-align: center;
    margin-bottom: 50px;

    .content {
        justify-content: center;
    }

    &-title {
        &__container {
            margin-top: 25px;
        }
    }

    &-description {
        &__container {
            margin-top: 15px;

            .description-title {
                width: 50%;

                @media screen and (max-width: 768px) {
                    width: 100%;
                  }
            }

            .winner-prizes{
                margin: 0;
                list-style-type: none;
            }

            .winner-prizes > li{
                text-indent: -5px;
            }

            .winner-prizes > li::before{
                content: '-';
                text-indent: -5px;
                margin-right: 5px;
            }
        }
    }

    &-modal {
        &__preview-container > img {
            width: 100%;
        }
    }

    &-receipt {
        &__container {
            background-color: #d9d9d9;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            width: 40%;

            @media screen and (max-width: 1200px) {
                width: 50%;
              }

            @media screen and (max-width: 992px) {
                width: 60%;
              }

            @media screen and (max-width: 768px) {
                width: 70%;
              }

            @media screen and (max-width: 576px) {
                width: 90%;
              }
        }

        &__page {
            background-color: white;
            border: 1px solid black;
            margin-top: 10px;
            margin-bottom: 10px;

            &-header {
                margin-top: 25px;

                .name-container > input {
                    text-align: center;
                    border-color: transparent;
                    padding: 5px;
                    font-size: 16px;
                    font-weight: bold;
                }

                .name-container > input:focus {
                    border-color: black;
                }

                .logo-container > .clickfrenzy-logo {
                    width: 25%;
                }
            }

            &-content {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 10px;

                .ant-select-selection-item, .ant-select-selection-placeholder {
                    font-size: 16px;
                }

                .row-container{
                    margin-bottom: 10px;

                    .item-container {
                        flex-direction: column;

                        .brand-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;

                            .brand-select {
                                width: 250px;
                                text-align: left;
                            }

                            .close-icon:hover {
                                color: red;
                                scale: 1.1;
                            }
                        }

                        .item-price-container {
                            display: flex;
                            justify-content: space-between;

                            .item-input {
                                font-size: 16px;
                                text-overflow: ellipsis;
                            }

                            .price-input {
                                width: 100px;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            &-footer {
                margin-top: 25px;
                margin-bottom: 25px;

                .row {
                    display: flex;
                    justify-content: center;

                    .divider {
                        height: 5px;
                        border-top: 2px dashed;
                    }
                }

                .footer1 {
                    margin-top: 10px;
                    margin-bottom: 20px;
                }

                .barcode {
                    margin-bottom: 20px;

                    img {
                        width: 60%;
                    }
                }
            }
        }
    }

    .cta {
        margin-top: 35px;

        .error-message {
            margin-top: 15px;
            color: red;
        }
    }
    /* stylelint-disable */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    /* stylelint-enable */
}