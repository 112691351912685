@import '../../../styles/import';

.search-results {
    display: none;

    &--visible {
        display: block;
    }

    &__footer {
        display: none;

        &--visible {
            display: flex;
        }
    }
}
