@import '../styles/import';

.not-found {
    $self: &;

    &__container {
        text-align: center;
        padding-top: 2rem;

        &--main-heading {
            font-size: 32px;
            line-height: 32px;
        }
    }

    &__cta-button {
        &-signup {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        &-home {
            margin-bottom: 15px;
        }

        @each $theme in $themes {
            &--#{$theme} {
                color: var(--event-#{$theme}-secondary-text-colour) !important;
                background-color: var(--event-#{$theme}-secondary-alternate) !important;

                path {
                    fill: var(--event-#{$theme}-secondary-text-colour);
                }

                &:hover {
                    color: var(--event-#{$theme}-secondary-text-colour);
                }
            }
        }
    }
}