/**
 ** DEAL CAROUSEL CSS
 **/
@import '../../../../../styles/import';

.cf-deal-carousel {
  .head-blk {
    margin-bottom: 25px;
  }

  .cf-box {
    position: relative;

    .slider-desktop {
      display: block;
    }

    .slider-mobile {
      display: none;
    }

    .slick-arrow {
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 26px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      top: -53px;
      right: 0;
      cursor: pointer;
      background-color: var(--cf-primary);

      > svg {
        margin-left: 2px;
        margin-right: 0;
      }

      &.slick-prev {
        right: 32px;

        > svg {
          margin-left: 0;
          margin-right: 2px;
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    .cf-box {
      .slider-desktop {
        display: none;
      }

      .slider-mobile {
        display: flex;
        width: calc(100vw);
        margin-left: -20px;
        margin-top: -15px;
        margin-bottom: -30px;
        overflow: hidden;
        overflow-x: auto;

        > .cf-flex {
          gap: 14px;
          padding: 15px 20px 30px;
        }
      }
    }
  }

  @include media-breakpoint-down('xxs') {
    .head-blk {
      margin-bottom: 24px;
      text-align: center;
    }
  }
}

.card-ct {
  @include font('barlow', 'xxs', 'sm', 'regular');

  color: var(--cf-grey2, #484b55);
  line-height: 160%;
  letter-spacing: -0.1px;

  .card-title {
    color: var(--cf-primary);
    font-size: 22px;
    line-height: 130%;
    margin-bottom: 7px;
  }

  .desc {
    margin-bottom: 19px;
  }

  a {
    color: var(--cf-grey2, #484b55);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .title-link {
    color: var(--cf-primary);
    text-decoration: none;
  }

  .logo-frame {
    width: 60px;
    margin-bottom: 27px;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .cta-blk {
    padding-top: 29px;

    .button {
      width: 170px;
      color: white;
      text-decoration: none;
    }
  }

  @include media-breakpoint-down('sm') {
    .logo-frame {
      margin: 0 auto;
      margin-bottom: 27px;
    }
  }

  @include media-breakpoint-down('xs') {
    .logo-frame {
      margin-bottom: 22px;
    }

    .card-title {
      font-size: 24px;
      margin-bottom: 9px;
    }

    .desc {
      margin-bottom: 26px;
    }
  }
}

.cf-deal-card {
  border-radius: 16px;
  overflow: hidden;

  .card-ct {
    background-color: white;
    flex: 0 0 430px;
    width: 430px;

    .inner {
      padding: 0 54px 0 60px;
    }

    .desc,
    .link-blk {
      opacity: 0.7;
    }

    .link-blk {
      text-decoration: underline;
      cursor: pointer;
      font-size: 10px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .card-img {
    height: 360px;

    > figure {
      margin-bottom: 0;
      overflow: hidden;

      img {
        height: 360px;
        width: auto;

        &.img-mobile {
          display: none;
        }
      }
    }

    @include media-breakpoint-down('md') {
      > figure {
        img {
          margin-left: -18%;
        }
      }
    }

    @include media-breakpoint-down('sm') {
      > figure {
        img {
          margin-left: 0;
          height: 225px;

          &.img-desktop {
            display: none;
          }

          &.img-mobile {
            display: block;
          }
        }
      }
    }
  }

  @include media-breakpoint-down('md') {
    .card-ct {
      flex: 0 0 50%;
      width: 50%;

      .inner {
        padding: 0 32px 0 48px;
      }
    }
  }

  @include media-breakpoint-down('sm') {
    width: 332px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 4px 20px 0 rgb(76 42 114 / 20%);

    .card-ct {
      flex: none;
      width: 100%;
      text-align: center;
      justify-content: center;

      .inner {
        padding: 40px 20px 20px;
      }
    }

    > .cf-flex {
      &.flex-row {
        flex-direction: column-reverse;
      }
    }

    .cta-blk {
      display: none;
    }

    .card-img {
      height: 225px;
    }
  }
}
