@import '../../styles/import';


.brand-detail-basic {
    &__primary {
        display: flex;
        flex-flow: row wrap;

        @include desktop() {
            flex-flow: row nowrap;
            align-items: center;
        }
    }

    &__logo-wrapper {
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        position: relative;
        border: 1px solid var(--light-grey);
        margin: 0 auto;
        flex-grow: 0;
        flex-shrink: 0;

        @include desktop() {
            margin: 0;
            width: 100px;
            height: 100px;
        }
    }

    &__logo-image {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__name-wrapper {
        text-align: center;
        box-sizing: border-box;
        display: block;
        width: 100%;
        margin-top: spacing('xl');

        @include desktop() {
            padding-left: spacing('3xl');
            text-align: left;
            display: flex;
            align-items: center;
            margin-top: 0;
        }
    }

    &__name {
        @include font('barlow', 'xxl', 'xl', 'heavy');

        margin-top: 0;
        color: var(--body);
        text-align: center;

        @include desktop() {
            @include font('barlow', 'xxxl', 'xxxl', 'heavy');

            text-align: left;
        }
    }

    &__secondary {
        margin-top: spacing('lg');

        @include desktop() {
            margin-top: spacing('6xl');
        }
    }

    &__description {
        @include font('barlow', 'md', 'lg', 'regular');

        color: var(--body);
        margin-top: 0;
        text-align: center;

        @include desktop() {
            text-align: left;
        }
    }
}