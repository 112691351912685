@import '../../../styles/import';

.search-header {
    $self: &;

    &--has-content {
        @include mobile() {
            padding-top: spacing('xl');
            padding-bottom: spacing('xl');
        }
    }

    @include mobile() {
        min-height: 20px;
    }

    @include desktop() {
        height: 60px;
    }

    &__status {
        @include font('barlow', 'md');
    }

    &__status-highlight {
        @include font('barlow', 'md', $weight: 'medium');
    }

    &__refine-wrapper {
        display: none;

        &--visible {
            display: block;
        }
    }

    &__refine {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--mobile {
            @include desktop() {
                display: none;
            }
        }

        &--desktop {
            @include mobile() {
                display: none;
            }
        }
    }
}