/* DEAL BADGE CSS */


.cf-deal-badge {
    display: inline-flex;
    background-color: white;
    padding: 5px 14px 3px;
    gap: 8px;
    border-radius: 60px;
    font-size: 10px;
    line-height: 150%;
    color: var(--cf-primary);

    &.state-ending {
        color: white;
        background-color: var(--cf-pink);
    }
    
    &.state-expired {
        color: white;
        background-color: #363941;
    }
}