@import '../../styles/import';

.logo {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0;
    flex-grow: 0;
    flex-shrink: 0;
    display: block;

    &--size-small {
        width: 24px;
        height: 24px;
    }

    &--size-block {
        width: 100%;
        padding-bottom: 100%;
    }

    &__image-wrapper {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__image {
        display: block;
        max-height: 100%;
        max-width: 100%;
        flex: 1; // IE fix
    }
}
