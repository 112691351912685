@import '../../styles/import';

/* stylelint-disable-next-line selector-class-pattern */
.calendarButton {
  width: 100%;
  max-width: 312px;
  margin-bottom: spacing('xl');
  position: relative;

  @include desktop() {
    max-width: 348px;
  }

  path {
    fill: inherit !important;
  }
}
