/***
 * CF MODAL BOX
 * Customize 07/2023 
 *** 
 */
@import '../../../styles/import';

.cf-modal-box {
    font-family: Barlow, Arial, Helvetica, sans-serif;

    .modal {
        &__overlay {
            background-color: rgb(20 20 20 / 90%);
            align-items: center;
            padding: 20px;
        }

        &__content {
            position: relative;
            border-radius: 16px;

            &--padding-large {
                padding: 60px 56px;
            }
        }

        &__close-button--inline {
            width: 26px;
            height: 26px;
            padding: 0;
            top: 24px;
            right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    &.width-md {
        &.modal {
            &--size-responsive {
                .modal {
                    &__content {
                        max-width: 564px;
                    }
                }
            }
        }
    }

    p {
        font-family: Barlow, Arial, Helvetica, sans-serif;
    }

    @include media-breakpoint-down('xxs') {
        .modal {
            &__content {
                &--padding-large {
                    padding: 60px 32px;
                }
            }
        }
    }
}