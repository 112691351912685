@import '../../styles/import';

.brand-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-top: spacing(xl);
    text-decoration: none;
    background: var(--white);

    @include desktop() {
        border-radius: spacing('2xs');
        padding: spacing('default');
        transition: box-shadow 300ms ease-in-out;

        .no-touchevents &:hover {
            box-shadow: $standardBoxShadow;
        }
    }

    &__logo-wrapper {
        width: spacing('7xl');
        height: spacing('7xl');
        position: relative;
        border: 1px solid var(--light-grey);
        flex-shrink: 0;

        @include desktop() {
            width: spacing('7xl');
            height: spacing('7xl');
        }

        @media screen and (min-width: 1140px) {
            display: none;
        }
    }

    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px var(--light-grey);
        margin-left: spacing(md);
        padding-bottom: spacing(xl);
        min-width: 0;
        overflow: hidden;

        @include desktop() {
            border: none;
            padding-bottom: 0;
        }
    }

    &__name {
        @include font(barlow, md, sm);

        color: var(--body);
        height: spacing('7xl');
        overflow: hidden;
        margin-right: spacing(lg);
        flex-shrink: 1;
        min-width: 0;
        display: flex;
        align-items: center;

        @include desktop() {
            @include font(barlow, sm, xs);

            height: spacing('7xl');
            display: flex;
            align-items: center;
        }

        @media screen and (min-width: 1140px) {
            @include font(barlow, md, xs);
        }
    }

    &__icon {
        margin-right: spacing('4xl');
        height: spacing('7xl');
        line-height: spacing('7xl');

        @include desktop() {
            display: none;
        }
    }
}