@import '../../styles/import';

.banner {
    display: block;
    line-height: 0;

    &__image {
        max-width: 100%;
    }
}
