/** COLORS **/
:root {
    --cf-primary: #2F334D;
    --cf-purple: #511478;
    --cf-purple2: #f8eaff;
    --cf-light-purple: #F8F4FD;
    --cf-blue: #FF705D;
    --cf-blue2: #238bc7;
    --cf-grey2: #484B55;
    --cf-grey3: #7D8090;
    --cf-grey4: #A0A4B8;
    --cf-grey5: #EFF1F9;
    --cf-pink: #E9028D;
    --cf-pink2: #c50076;
    --cf-orange: #F05922;
    --cf-yellow: #F7F410;
}

// Text Color
.text-primary {
    color: var(--cf-primary);
}

// Background
.bg-primary {
    background-color: var(--cf-primary);
}

.bg-purple {
    background-color: var(--cf-purple);
}

.bg-blue {
    background-color: var(--cf-blue);
}

.bg-light-violet {
    background-color: var(--cf-light-purple);
}

.bg-white {
    background-color: white;
}