@import '../../styles/import';

.dropdown-menu {
    background: var(--white);
    flex-direction: column;
    overflow: hidden;
    display: none;

    @include mobile() {
        @include z-index('overlay');

        position: fixed;
        inset: 0;
    }

    @include desktop() {
        @include z-index('below-layout-header');

        position: absolute;
        top: 100%;
        right: 0;
        box-shadow: $standardBoxShadow;
        border-radius: 4px;
        height: 600px;
        width: 370px;
    }

    &--enter {
        opacity: 0;
        display: flex;
    }

    &--enter-done {
        display: flex;
    }

    &--enter-active {
        opacity: 1;
        transition: opacity 300ms;
    }

    &--exit {
        opacity: 1;
    }

    &--exit-active {
        opacity: 0;
        transition: opacity 300ms;
    }

    &--exit-done {
        display: none;
    }

    &__header {
        @include font('barlow', 'xxl', 'sm', 'medium');

        text-align: center;
        position: relative;
        padding: spacing('6xl') spacing('8xl');
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__body {
        padding: 0 spacing('xl') spacing('xl');
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    &__close-button {
        position: absolute;
        right: spacing('5xl');
        top: 50%;
        transform: translateY(-50%);
    }
}