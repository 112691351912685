@import '../../styles/import';

.cfa-image-upload {
  $parent: &;

  display: flex;
  flex-direction: column;
  padding: spacing('4xl');
  flex: 1;
  align-items: center;
  text-align: center;
  background-color: var(--white);
  border: 1px solid var(--input-border);

  /* stylelint-disable-next-line selector-class-pattern */
  .uploadcare--widget {
    margin-bottom: spacing('4xl');
  }

  &__content {
    max-width: 100%;
  }

  &__description {
    clear: both;
    min-height: 22px;
    color: var(--black-transparent);
    font-size: 14px;
    line-height: 1.5;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &__img {
    align-self: center;
    margin-bottom: spacing('4xl');
    max-width: 100%;

    #{$parent}--disabled & {
      margin-bottom: 0;
    }

    &[src$='.svg'] {
      width: 100%;
    }
  }
}
