@import '../../styles/import';

.social-button {
  text-transform: uppercase;

  &--facebook {
    background: var(--facebook);
  }

  &--google {
    background: var(--google);
  }

  &__icon {
    margin-right: spacing('lg');
  }

  &__icon svg {
    display: block;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .button__children {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
