/// The number of pixels a border radius should use.
///
/// @group tokens
/// @type Length
$border-radius: 8px !default;

/// The number of pixels a small border radius should use.
///
/// @group tokens
/// @type Length
$border-radius--small: 4px !default;
