@import '../../styles/import';

.grid-example {
    &__cell {
        background: var(--secondary);
        color: var(--white);
        overflow: hidden;
        margin-top: 1rem;
        padding-left: 1rem;
    }
}
