@import '../../styles/import';

.container {
    list-style: none;
    display: inline-flex;
    padding: 0;
}

.item {
    display: inline-flex;
}

.title {
    @include font('barlow', 'sm', 'sm', 'heavy');

    color: var(--body);
}

a .title {
    color: var(--primary);
}

.chevron {
    margin: 0 spacing('md');
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        height: 1em;

        // path {
        //     stroke: #2e3192;
        // }
    }
}

@each $theme in $themes {
    .chevron-#{$theme} {
        svg {
            path {
                stroke: var(--event-#{$theme}-breadcrumb-chevron-stroke-color);
            }
        }
    }
}

.link {
    @include underline-on-hover();
}