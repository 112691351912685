@import '../../../styles/import';

.search-sort {
  $self: &;

  display: flex;
  align-items: center;
  position: relative;

  &--context-modal {
    padding: spacing('xl') 0;
  }

  &__label {
    @include font('barlow', 'md', $weight: 'medium');

    padding-right: spacing('default');
    color: var(--secondary-alternate);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    #{$self}--context-modal & {
      left: $flexboxgrid-gutter-width;
    }
  }

  &__label-separator {
    @include mobile() {
      display: none;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &--context-modal .ais-SortBy {
    display: block;
    flex: 0 0 100%;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .ais-SortBy-select {
    @include font('barlow', 'md', $weight: 'medium');

    color: var(--secondary-alternate);
    background: url('../../../static/icons/select.svg') no-repeat right 0 top 50%;
    border: 0;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: none;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    padding: spacing('default') 18px spacing('default') 0;

    &::-ms-expand {
      display: none;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &--context-modal .ais-SortBy-select {
    @include font('barlow', 'md', $weight: 'regular');

    background: url('../../../static/icons/chevron.svg') no-repeat right $flexboxgrid-gutter-width top 50%;
    color: var(--body);
    direction: rtl;
    padding-right: spacing('7xl');
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .ais-SortBy-select option {
    color: var(--body);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &--context-modal .ais-SortBy-select option {
    direction: rtl;
  }
}