/* These styles are for homepage deal cards */
/* stylelint-disable scss/dollar-variable-pattern */
@import '../../styles/import';

$minXLScreenWidth: 1320px;
$minLScreenWidth: 1227px;
$minMScreenWidth: 768px;

.deal-card {
  $self: &;

  width: 320px;
  height: 577px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  position: relative;
  margin: spacing('lg') 0 spacing('6xl');
  font-family: font-family('barlow');

  @media screen and (max-width: $minXLScreenWidth) {
    width: 298px;
    height: 544px;
  }

  @media screen and (max-width: $minLScreenWidth) {
    width: 221px;
    height: 448px;
  }

  @media screen and (max-width: $minMScreenWidth) {
    width: 260px;
    height: 471px;
  }

  &__hover-effect:hover {
    transform: scale(1.05);
    box-shadow: $hoverShadow;
  }

  &__image-wrapper {
    height: 320px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #eee;

    @media screen and (max-width: $minXLScreenWidth) {
      height: 298px;
    }

    @media screen and (max-width: $minLScreenWidth) {
      height: 220px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      height: 260px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  &__exclusive-deal {
    width: 100%;
    background-color: #ed008c;
    font-size: 1em;
    font-weight: 400;
    color: rgb(255 255 255);
    border-radius: 4px 4px 0 0;
    border-width: 1px 1px 0;
    border-color: #eee;
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop() {
      font-size: 1.2em;
      height: 50px;
    }
  }

  &__brand-wrapper {
    max-width: 176px;
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
    border: 1px solid #eee;
    position: absolute;
    top: 289px;
    left: 18px;
    background: #fff;
    padding: 7.5px;
    display: flex;

    @media screen and (max-width: $minXLScreenWidth) {
      max-width: 166px;
      min-width: 50px;
      height: 50px;
      top: 270px;
      left: 19px;
    }

    @media screen and (max-width: $minLScreenWidth) {
      max-width: 156px;
      min-width: 40px;
      height: 40px;
      top: 200px;
      left: 15px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      max-width: 156px;
      min-width: 50px;
      height: 50px;
      top: 236px;
      left: 13px;
    }
  }

  &__brand-image {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: contain;
  }

  &__details-wrapper {
    width: inherit;
    height: 200px;
    position: relative;

    @media screen and (max-width: $minXLScreenWidth) {
      height: 189px;
    }

    @media screen and (max-width: $minLScreenWidth) {
      height: 177px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      height: 168px;
    }
  }

  &__title {
    padding: 40px 19px 0;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    @media screen and (max-width: $minXLScreenWidth) {
      padding: 35px 19px 0;
      font-size: 16px;
    }

    @media screen and (max-width: $minLScreenWidth) {
      padding: 35px 14px 0;
      font-size: 13px;
      line-height: 18px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      padding: 39px 12px 0;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__upcoming-exclusive-title {
    padding: 40px 19px 0;

    @media screen and (max-width: $minXLScreenWidth) {
      padding: 35px 19px 0;
    }

    @media screen and (max-width: $minLScreenWidth) {
      padding: 35px 14px 0;
    }

    @media screen and (max-width: $minMScreenWidth) {
      padding: 31px 12px 0;
    }
  }

  &__upcoming-exclusive-timer-title {
    position: absolute;
    bottom: 0;
    padding: 0 19px 16px;

    @media screen and (max-width: $minLScreenWidth) {
      padding: 0 14px 16px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      padding: 0 12px 16px;
    }
  }

  &__exclusive-countdown-timer {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    padding-left: 19px;

    @media screen and (max-width: $minXLScreenWidth) {
      font-size: 20px;
      line-height: 24px;
    }

    @media screen and (max-width: $minLScreenWidth) {
      padding-left: 14px;
      font-size: 18px;
      line-height: 21px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      padding-left: 12px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__offer {
    padding: 5px 19px;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    color: var(--text-gray);
    text-align: center;

    @media screen and (max-width: $minXLScreenWidth) {
      font-size: 12px;
      line-height: 14px;
    }

    @media screen and (max-width: $minLScreenWidth) {
      padding: 4px 14px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      padding: 4px 12px;
    }
  }

  &__optionals-wrapper {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 0 19px 16px;
    position: absolute;
    bottom: 0;
    color: var(--text-light-gray);
    width: 100%;
    text-align: center;

    @media screen and (max-width: $minLScreenWidth) {
      padding: 0 14px 16px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      padding: 0 12px 16px;
    }
  }

  &__actions-wrapper {
    width: inherit;
    height: 54px;
    border-top: 1px solid #eee;
    display: flex;

    @media screen and (max-width: $minLScreenWidth) {
      height: 49px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      height: 41px;
    }
  }

  &__cta-wrapper {
    width: 263px;
    height: inherit;

    @media screen and (max-width: $minXLScreenWidth) {
      width: 242px;
    }

    @media screen and (max-width: $minLScreenWidth) {
      width: 173px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      width: 219px;
    }
  }

  &__cta {
    width: inherit;
    height: inherit;

    // background: #0095da;
    border-radius: 0 0 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    @each $theme in $themes {
      &.cta--#{$theme} {
        @if $theme =='travel' {
          background-image: var(--event-#{$theme}-deal-card-cta-btn-background-image);
        }

        @else {
          background-color: var(--event-#{$theme}-deal-card-cta-btn-background-color);
        }
      }
    }
  }

  &__cta-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cta-text {
    color: var(--white);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    @media screen and (max-width: $minXLScreenWidth) {
      font-size: 20px;
      line-height: 24px;
    }

    @media screen and (max-width: $minLScreenWidth) {
      font-size: 18px;
      line-height: 21px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__cta-icon {
    padding-left: 7px;

    @media screen and (max-width: $minLScreenWidth) {
      padding-left: 6px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      padding-left: 5px;
    }
  }

  &__cta-svg-icon {
    @media screen and (max-width: $minMScreenWidth) {
      width: 11px;
      height: 9px;
    }
  }

  &__favourite-wrapper {
    width: 57px;
    height: inherit;

    @media screen and (max-width: $minLScreenWidth) {
      width: 48px;
    }

    @media screen and (max-width: $minMScreenWidth) {
      width: 40px;
    }
  }

  &__favourite-cta {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.exclusives-popup {
  $self: &;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px
  }

  @each $theme in $themes {
    &__login-cta-#{$theme} {
      border-radius: 4px !important;
      background-color: var(--event-#{$theme}-login-cta-background-color) !important;
      border: none !important;
    }
  }
}