@import '../../../../../styles/import';

.cf-board-container {
    overflow: hidden;
}

.cf-board-blk {
    color: white;
    padding: 180px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    position: relative;
    
    .content {
        width: 400px;

        @include media-breakpoint-down('lg') {
            width: 320px;
        }
    }

    .ttl {
        color: white;
        margin-bottom: 10px;
        margin-top: 0;
    }

    .desc {
        margin-bottom: 24px;
    }

    .img {
        position: absolute;
        bottom: -200px;
        right: -300px;
        width: 1000px;

        @include media-breakpoint-down('xlg') {
            right: -400px;
        }

        @include media-breakpoint-down('lg') {
            right: calc(100vw - 1280px);
        }

        @include media-breakpoint-down('md') {
            left: -40px;
            right: unset;
        }

        @include media-breakpoint-down('xs') {
            left: -30px;
            bottom: 67px;
            width: 564px;
        }
    }

    @include media-breakpoint-down('md') {
        padding: 100px 0 540px;

        .content {
            width: 400px;
            margin: 0 auto;
            text-align: center;

            .cta-blk {
                > .button {
                    margin: 0 auto;
                }
            }
        }
    } 

    @include media-breakpoint-down('xs') {
        padding: 80px 0 540px;

        .desc {
            margin-bottom: 60px;
        }

        .content {
            width: auto;
            max-width: 350px;
        }
    }
}