@import '../../styles/import';

/* stylelint-disable-next-line selector-class-pattern */
.membershipBenefits {
  @include font('barlow', 'xxl', 'xl', 'heavy');

  &__main-title {
    @include font('barlow', 'xxl', 'xl', 'medium');

    margin-top: spacing('6xl');
    text-align: center;

    @include desktop() {
      margin-top: spacing('7xl');
    }
  }
}