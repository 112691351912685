@import '../../styles/import';

.modal-header {
    position: relative;
    padding: 0 $flexboxgrid-gutter-width;

    @include desktop() {
        padding: 0;
    }

    &--padding-none {
        padding: 0;
    }

    &__title {
        text-align: center;
        margin: 0;
        padding: spacing('2xl') 0;
    }
}
