@import '../../../styles/import';

.button {
    $selector: &;

    font-family: Barlow, Arial, Helvetica, sans-serif;
    font-weight: normal;
    border-radius: spacing('7xl');
    padding: 4px 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;
    line-height: 1;
    transition: all 0.25s ease-out;
    box-shadow: none;
    border: 1px solid var(--cf-primary);
    background-color: transparent;
    color: var(--cf-primary);

    &:hover {
        background-color: var(--cf-purple2);
    }

    &:disabled {
        opacity: 0.7;
        cursor: default;
    }


    &--type-blue {
        background: var(--cf-blue);
        border: 1px solid var(--cf-blue);
        color: white;
        cursor: pointer;

        &:hover {
            background: var(--cf-blue2);
            border: 1px solid var(--cf-blue2);
        }
    }

    &--type-pink {
        background: var(--cf-pink);
        border: 1px solid var(--cf-pink);
        color: white;
        cursor: pointer;

        &:hover {
            background: var(--cf-pink2);
            border: 1px solid var(--cf-pink2);
        }
    }

    &--type-cfprimary {
        background: var(--cf-primary);
        border: 1px solid var(--cf-primary);
        color: white;
        cursor: pointer;

        &:hover {
            background: var(--cf-purple);
            border: 1px solid var(--cf-purple);
        }
    }

    &--type-cfwhiteoutline {
        font-family: Barlow, Arial, Helvetica, sans-serif;
        background: transparent;
        border: 1px solid white;
        color: white;
        cursor: pointer;

        &:hover {
            background: rgb(255 255 255 / 30%);
            border: 1px solid white;
        }
    }

    &--type-cfwhiteblue {
        background: white;
        border: 1px solid white;
        color: var(--cf-blue);
        cursor: pointer;

        &:hover {
            background: rgb(255 255 255 / 70%);
            border: 1px solid rgb(255 255 255 / 70%);
        }
    }

    &--display-block {
        display: block;
        width: 100%;
    }

    &--action-redirect {
        text-decoration: none;
        text-align: center;
    }

    &--size-small {
        font-family: Barlow, Arial, Helvetica, sans-serif;
        padding: 5px 16px 4px;
        height: 26px;
        font-size: 11px;
        font-weight: 500;
    }

    &--size-large {
        padding: 7px 24px 6px;
        height: 44px;
        font-size: 14px;

        @include media-breakpoint-down('xxs') {
            height: 44px;
            font-size: 16px;
        }
    }

    &--has-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        margin-right: spacing('md');
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}