@import '../../../styles/import';

.cfa-popup {
    &__modal {
        max-width: 90%;

        .ant-modal-content {
            background-color: var(--primary);
            border-radius: 5px;
            box-shadow: 5px 5px 20px var(--body);
        }

        .ant-modal-footer {
            display: none;
        }

        @each $theme in $themes {
            &--#{$theme} {
                .ant-modal-content {
                    background-color: var(--event-#{$theme}-background-colour);
                }

                .ant-btn-primary {
                    color: var(--event-#{$theme}-secondary-text-colour) !important;
                    background-color: var(--event-#{$theme}-secondary-alternate) !important;
                }
            }
        }

        @each $theme in $themes {
            &--#{$theme}#{&}--noAddress {
                text-align: center !important;

                h1 {
                    color: var(--event-#{$theme}-text-colour);
                    margin-bottom: spacing(xl);
                }

                p {
                    @include font(catamaran, md, sm, regular);
                }

                .ant-modal-footer {
                    display: block;

                    button {
                        border: none;
                        color: var(--black);
                    }
                }
            }
        }
    }
}
